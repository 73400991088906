/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
import axios, { AxiosRequestConfig } from 'axios';

//axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';
//axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
axios.defaults.validateStatus = function (status) {
  if (status >= 500) {
    // TODO: commenting for now
    // history.push(`/exception/${status}`);
  } else if (status === 404) {
    // history.push('/exception/404');
  } else if (status >= 400) {
    /*toast({
      position: 'top',
      title: `Server Error.`,
      description: message.messageText,
      status: 'error',
      isClosable: true,
    })*/
  }
  return status >= 200 && status < 300; // default
};

axios.interceptors.request.use((req: any) => {
  const access_token = sessionStorage.getItem('authentication');
  // console.log(access_token);
  if (access_token) {
    if (access_token) {
      req.headers['Authorization'] = `Bearer ${access_token}`;
    }
  }

  return req;
});

axios.interceptors.response.use(
  response => response,
  error => {
    // errorToast({
    //   title: 'Server Error!',
    //   description: error.message + response.name,
    // });
    throw error;
  },
);

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  if (response?.data?.errors) {
    /*map(response.data.messages, message =>
      toast({
        position: 'top',
        title: `Server Error.`,
        description: message.messageText,
        status: 'error',
        isClosable: true,
      }),
    );*/
  }
  return response.data;
}

/**
 * Checks if a network request came back fine, and throws an error otherwise
 *
 * @param  {object} response   Response from a network request
 *
 * @return {object|undefined} Returns either response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  //error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */

export default function request(url: string, options?: AxiosRequestConfig) {
  // console.log(axios(url, options).then(checkStatus).then(parseJSON));
  return axios(url, options).then(checkStatus).then(parseJSON);
}
