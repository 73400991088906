import React, { FC, useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Card } from 'app/components';
import { CustomTable2 as Table } from 'app/components/DataDisplay/NewTable/Table';
import {
  renderOrganizationActions,
  renderOrganizationStatus,
} from 'app/containers/Visibility/utils';
import { renderTime } from 'components/DataDisplay';
import { useTrackApiCall } from 'hooks/useTrackApiCall';

import { selectOrganizations } from '../selectors';
import { actions } from '../slice';

interface IOrganizationTable {}

export const OrganizationTable: FC<IOrganizationTable> = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { organizations, enableOrganization, deleteOrganization } = useSelector(
    selectOrganizations,
  );
  const enableOrgCall = useTrackApiCall(enableOrganization);
  const deleteOrgCall = useTrackApiCall(deleteOrganization);

  // fetch organizations
  useEffect(() => {
    dispatch(actions.getOrganizations({}));
  }, [dispatch]);

  useEffect(() => {
    if (enableOrgCall.success) {
      enableOrgCall.done();
      dispatch(actions.getOrganizations({}));
    }
  }, [enableOrgCall, dispatch]);

  useEffect(() => {
    if (deleteOrgCall.success) {
      deleteOrgCall.done();
      dispatch(actions.getOrganizations({}));
    }
  }, [deleteOrgCall, dispatch]);

  const onEnableChange = record => {
    return () => {
      dispatch(
        actions.changeEnableOrganization({
          ...record,
          enabled: !record.enabled,
        }),
      );
    };
  };

  const onEdit = row => {
    history.push(`/organizations/edit/${row.name}`); //new
  };

  const handleDelete = row => {
    dispatch(actions.deleteOrganization({ organization: row.name }));
    // history.push(`/organizations/edit/${row.id}`); //new
  };

  const columns = [
    {
      header: 'Name',
      accessor: 'name',
    },
    { header: 'Admins', accessor: 'num_admin_users' },
    { header: 'Created At', accessor: 'creation_time', render: renderTime },
    { header: 'Created By', accessor: 'creator.username' },
    {
      header: 'Status',
      accessor: 'enabled',
      render: renderOrganizationStatus,
      align: 'center',
    },
    {
      header: 'Actions',
      render: ({ row }) =>
        renderOrganizationActions({
          row,
          onEnableChange: onEnableChange(row),
          onDelete: () => handleDelete(row),
          onEdit: () => onEdit(row),
        }),
      styles: { cell: { overflow: 'visbile' } },
    },
  ];

  return (
    <Box flex={1}>
      <Card>
        <Box w={'full'} h={'full'}>
          <Table
            columns={columns}
            data={organizations.data}
            isLoading={organizations.loading}
          />
        </Box>
      </Card>
    </Box>
  );
};
