import React from 'react';

import { Icon } from '@chakra-ui/react';

export const AzureIcon = props => (
  <Icon
    color="#0072c6"
    viewBox={'0 0 59.242 47.271'}
    h="full"
    w="full"
    {...props}
  >
    <path d="M32.368,0,14.9,15.145,0,41.895H13.437Zm2.323,3.543L27.237,24.551,41.528,42.507,13.8,47.271H59.242Z" />
  </Icon>
);
