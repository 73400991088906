import React, { FC, Suspense } from 'react';

import { Switch } from 'react-router-dom';

import { FilterRouter } from 'components/FilterView';
import { NotFoundPage } from 'components/NotFoundPage';
import { Compliance } from 'containers/Compliance/Loadable';
import { ConversationalAI } from 'containers/ConversationalAI';
import { Dashboard } from 'containers/Dashboard/Loadable';
import { EventLog } from 'containers/EventLog';
import { Findings } from 'containers/Findings/Loadable';
import { Inventory } from 'containers/Inventory/Loadable';
import { Meta } from 'containers/Meta';
import { Organizations } from 'containers/Organizations';
import { PolicyHub } from 'containers/PolicyHub/Loadable';
import { Reports } from 'containers/Reports/Loadable';
import { Settings } from 'containers/Settings/Loadable';
import { Setup } from 'containers/Setup/Loadable';
import { UserProfile } from 'containers/UserProfile/Loadable';
import { Visibility } from 'containers/Visibility/Loadable';
import { PageLoader } from 'utils/PageLoader';

interface IAppRoutes {}

export const AppRoutes: FC<IAppRoutes> = () => {
  return (
    <Switch>
      <Suspense fallback={<PageLoader />}>
        <FilterRouter exact path="/" component={Dashboard} />
        <FilterRouter path="/dashboard" component={Dashboard} />
        <FilterRouter path="/findings" component={Findings} />
        <FilterRouter path="/policy-hub" component={PolicyHub} />
        <FilterRouter path="/setup" component={Setup} />
        <FilterRouter
          path="/organizations"
          render={props => <Organizations {...props} />}
        />
        <FilterRouter path="/reports" component={Reports} />
        <FilterRouter path="/visibility" component={Visibility} />
        <FilterRouter path="/settings/:type" component={Settings} />
        <FilterRouter path="/inventory" component={Inventory} />
        <FilterRouter path="/user-profile" component={UserProfile} />
        <FilterRouter path="/compliance" component={Compliance} />
        <FilterRouter path="/event-log" component={EventLog} />
        <FilterRouter path="/meta" component={Meta} />
        <FilterRouter path="/home" component={ConversationalAI} />

        <FilterRouter component={NotFoundPage} />
      </Suspense>
    </Switch>
  );
};
