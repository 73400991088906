export const defaultStyles = {
  inputBox: {
    fontSize: 'fontSize',
    h: 'full',
    w: 'full',
    borderRadius: '3px',
    py: '6px',
    px: 3,
    border: '1px solid',
    borderColor: 'gray.200',
    _hover: {
      borderColor: 'primary',
    },
  },
};
