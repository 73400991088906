import React, { FC, useCallback, useEffect, useReducer, useState } from 'react';

import { Clients } from '@ariksa/notification';
import { NotificationFor } from '@ariksa/notification/api';
import { Box, HStack, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNotificationClients } from 'services/Notification/useClients';
import { Optional } from 'types/utils';

import { DetailsIdentifier, StackedHeader } from 'components/DataDisplay';
import { PrimaryButton, Select } from 'components/DataEntry';
import { IconTypes } from 'components/Icons';
import { Modal } from 'components/Overlay';
import { errorToast, successToast } from 'components/Toast';
import { selectActiveEnvironment } from 'containers/App/selectors';
import { EnvironmentName } from 'containers/App/utils';
import {
  notificationServices,
  ticketingServices,
} from 'containers/Findings/Alerts/Components/utils';
import { selectAlerts } from 'containers/Findings/Alerts/selectors';
import { actions } from 'containers/Findings/Alerts/slice';
import { NotificationResource } from 'containers/Findings/Alerts/types';

interface ICreateNotification {
  alert: NotificationResource;
  notification_for: NotificationFor;
  onClose();

  isOpen: boolean;
  onCreate?(data, alert);
}

export const CreateNotification: FC<ICreateNotification> = props => {
  const { alert, notification_for, isOpen, onClose, onCreate } = props;

  const dispatch = useDispatch();
  const { clients } = useNotificationClients();
  const { environmentId } = useSelector(selectActiveEnvironment);
  const { alertAction } = useSelector(selectAlerts);

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      clientType: {},
      clientOptions: [],
      targetOptions: [],
      selectedTarget: {},
    },
  );

  useEffect(() => {
    clients.sync({});
  }, [clients]);

  //set client options
  useEffect(() => {
    const options = notificationServices.filter(t =>
      clients.data?.find(c => c.client_name === t.value),
    );
    updateState({
      clientOptions: options,
      clientType: options[0],
    });
  }, [clients.data]);

  //set target options
  useEffect(() => {
    const options = clients.data
      ?.filter(c => c.client_name === state.clientType?.value)
      ?.map(c => ({
        label: c.name,
        value: c.name,
      }));
    updateState({
      targetOptions: options,
      selectedTarget: options?.[0],
    });
  }, [clients.data, state.clientType]);

  const handleCreateNotification = useCallback(
    data => {
      dispatch(
        actions.createNotification({
          q: {
            notifyClients: {
              client_name: state.clientType?.value as Clients,
              name: state?.selectedTarget?.value,
              alert_uuid: alert.uuid,
              resource_id: alert.entity_id,
              entity_uuid: alert.entity_uuid,
              resource_type: alert.entity_type,
              account_id: alert.account_id,
              alert_rule_id: alert.alert_rule_id,
              notification_for,
            },
          },
          onSuccess: () => {
            successToast({
              title:
                'Successfully sent notification to ' +
                state?.selectedTarget?.value,
            });
            onClose();
            onCreate?.(
              {
                ...data,
                clientType: state.clientType?.value,
                channelName: state?.selectedTarget?.value,
              },
              alert,
            );
          },
          onError: error => {
            errorToast({
              title:
                'Failed to send notification to ' +
                state?.selectedTarget?.value,
              description: error?.description,
            });
            onClose();
          },
        }),
      );
    },
    [
      alert,
      state.selectedTarget,
      state.clientType,
      dispatch,
      notification_for,
      onClose,
      onCreate,
    ],
  );

  return (
    <Box>
      <Modal
        styles={{ modal: { size: '2xl' } }}
        isOpen={isOpen}
        onClose={onClose}
        header={
          <StackedHeader
            upper={'Send Notification'}
            lower={
              (alert.entity_name ?? '') +
              (alert?.entity_id ? ' (' + alert.entity_id + ')' : '')
            }
            type={IconTypes.Notification}
          />
        }
        body={
          <Stack spacing={6}>
            <Stack spacing={6}>
              {notification_for === NotificationFor.Alerts && (
                <DetailsIdentifier
                  label={'Policy blueprint'}
                  value={alert.policy_name}
                />
              )}
              <DetailsIdentifier
                label={'Environment'}
                value={<EnvironmentName environmentId={environmentId} />}
              />
              <DetailsIdentifier
                label={'Select client'}
                value={
                  <Box w={'200px'}>
                    <Select
                      options={state.clientOptions}
                      value={state.clientType}
                      onChange={opt => updateState({ clientType: opt })}
                      isLoading={clients.isLoading}
                      isDisabled={clients.isLoading}
                    />
                  </Box>
                }
                align="center"
              />
              <DetailsIdentifier
                label={'Select target'}
                align="center"
                value={
                  <Box w={'200px'}>
                    <Select
                      isDisabled={clients.isLoading}
                      options={state.targetOptions}
                      value={state.selectedTarget}
                      isLoading={clients?.isLoading}
                      onChange={opt => updateState({ selectedTarget: opt })}
                    />
                  </Box>
                }
              />
            </Stack>
            <HStack justify={'space-between'} pt={10}>
              <PrimaryButton onClick={onClose}>Cancel</PrimaryButton>
              <PrimaryButton
                onClick={handleCreateNotification}
                isLoading={alertAction.isLoading}
                isDisabled={
                  !state.clientType?.value || !state.selectedTarget?.value
                }
              >
                Send
              </PrimaryButton>
            </HStack>
          </Stack>
        }
      />
    </Box>
  );
};
