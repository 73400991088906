import React, { FC } from 'react';

import { Route } from 'react-router-dom';

import { useUserPermissionsContext } from './UserPermissionsContext';

interface IFilterRouter {
  isLoading?: (() => boolean) | boolean;
  component?: any;
  render?: any;
  path?: string;
  exact?: boolean;
  show?: boolean;
}

export const FilterRouter: FC<IFilterRouter> = props => {
  const { path = '', show = true } = props;

  const { userPermission } = useUserPermissionsContext();
  // console.log(path);

  if (!userPermission.allowedRoute(path) || !show) {
    // console.log(path, null);
    return <></>;
  }

  return <Route {...props} key={path} />;
};
