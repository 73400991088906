import React, { FC, useEffect, useMemo, useState } from 'react';

import { Box, FormControl, Text, Skeleton } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { EmailIdsFieldSchema, EmailIdsFieldStyles } from 'components/DataEntry';
import { EmailIDs } from 'components/DataEntry/EmailIDs';

import { useStyles } from '../index';
import { FieldProps } from '../types';

import { FormLabel } from './FormLabel';

export const EmailIDsField: FC<FieldProps<EmailIdsFieldSchema>> = ({
  id,
  name,
  field,
  isLoading,
}) => {
  const {
    label,
    onChange,
    helperText,
    isRequired,
    helpIcon,
    shouldDisplay,
    styles = {},
    value,
    helpTooltip,
    onClickHelpIcon,
    defaultValue,
    ...rest
  } = field;

  const fieldStyles = useStyles<EmailIdsFieldStyles>('emailIds', styles);
  const { register, watch, setValue } = useFormContext();
  const values = watch({ nest: true });
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    register(name);
  }, [register, name]);

  useEffect(() => {
    if (defaultValue !== undefined && defaultValue !== null) {
      setValue(name, defaultValue);
    }
  }, [defaultValue, name, setValue]);

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setValue(name, value);
    }
  }, [value, name, setValue]);

  const isVisible = useMemo(() => {
    return shouldDisplay ? shouldDisplay(values) : true;
  }, [values, shouldDisplay]);

  return isVisible ? (
    <FormControl
      key={`${name}-control`}
      isInvalid={!!errorMessage}
      {...fieldStyles.control}
    >
      {!!label && (
        <FormLabel
          htmlFor={name}
          label={label}
          helpIcon={helpIcon}
          styles={fieldStyles.label}
          helpTooltip={helpTooltip}
          isRequired={isRequired}
          onClickHelpIcon={onClickHelpIcon}
        />
      )}
      <Box flex="1">
        <Skeleton isLoaded={!isLoading}>
          <EmailIDs
            value={value}
            onChange={ids => {
              setValue(name, ids);
              onChange?.(ids);
            }}
            setErrorMessage={setErrorMessage}
            defaultValue={defaultValue}
            {...rest}
          />
        </Skeleton>

        {!!helperText && (
          <Text color="gray.200" mt="1" fontSize="12">
            {helperText}
          </Text>
        )}
        {!!errorMessage && (
          <Text color="red.400" mt="1" fontSize="12">
            {errorMessage}
          </Text>
        )}
      </Box>
    </FormControl>
  ) : null;
};
