/**
 *
 * GovernanceChecklist
 *
 */

import React, { FC, useEffect, useState } from 'react';

import { CloudAccountGetResponse } from '@ariksa/cloud-account/api';
import { CheckList } from '@ariksa/compliance-policies';
import { NotificationFor } from '@ariksa/notification/api';
import {
  Box,
  HStack,
  Stack,
  Link,
  Text,
  Center,
  OrderedList,
  ListItem,
  useDisclosure,
  Square,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import capitalize from 'lodash/capitalize';
import map from 'lodash/map';
import { useSelector } from 'react-redux';

import { H3 } from 'components/DataDisplay';
import { Select } from 'components/DataEntry';
import { ConsoleIcon, ExclamationTriangleIcon } from 'components/Icons';
import { NotificationActions } from 'containers/Setup/CloudAccounts/Components/GovernanceChecklist/NotificationActions';
import { selectCloudAccounts } from 'containers/Setup/CloudAccounts/selectors';

import { renderRemediationEffort } from './utils';

interface Props {
  check: CheckList;
  account: CloudAccountGetResponse;
}

export const GovernanceCheck: FC<Props> = props => {
  const { check, account } = props;
  const {
    governanceCheckDetails: checkDetails,
    governanceCheckRemediation: remediation,
  } = useSelector(selectCloudAccounts);
  const alertAction = useDisclosure();
  const [details, setDetails] = useState<Record<string, any>>({});
  const [workflowType, setWorkflowType] = useState<Record<string, any>>();

  useEffect(() => {
    checkDetails?.data && setDetails(checkDetails?.data[check.id]);
  }, [check, checkDetails]);

  //const command = '';

  const startWorkflowOptions = [
    { label: 'Create Ticket', value: 'ticket' },
    { label: 'Create Notification', value: 'notification' },
  ];

  return (
    <>
      <Stack justify="space-between" pl={9} spacing={8} pt={3}>
        <Stack spacing={3}>
          <Box fontWeight={600}>Description</Box>
          <Box>{details?.description}</Box>
        </Stack>
        <Stack isInline align="baseline">
          <HStack w="65%">
            <HStack spacing={4} w="full">
              <Box fontWeight={600}>Severity: </Box>
              <HStack spacing={2}>
                <Square
                  size={4}
                  bg={details?.severity?.toLowerCase()}
                  borderRadius={4}
                ></Square>
                <Box>{capitalize(details?.severity)}</Box>
              </HStack>
            </HStack>
            <HStack w="full">
              <Box fontWeight={600}>Remediation Effort: </Box>
              <Box>{renderRemediationEffort(details?.remediation_effort)}</Box>
            </HStack>
          </HStack>
          <HStack w="35%" justify="end" spacing={6}>
            <Box whiteSpace="nowrap" fontWeight={600}>
              Actions:
            </Box>
            <Stack w="180px" spacing={4}>
              {/*<Center bg="primary" color="white" borderRadius={3} py={1}>
                Remediate
              </Center>*/}
              <Box>
                <Select
                  placeholder="Start Workflow"
                  options={startWorkflowOptions}
                  value={workflowType}
                  onChange={selected => {
                    setWorkflowType(selected);
                    alertAction.onOpen();
                  }}
                  isDisabled={check.status === 'Rule_Pass'}
                  styles={{
                    placeholder: styles => {
                      return {
                        ...styles,
                        width: '100%',
                        textAlign: 'right',
                      };
                    },
                  }}
                ></Select>
              </Box>
            </Stack>
          </HStack>
        </Stack>
        <Stack spacing={3}>
          <Box fontWeight={600}>Remediation Links</Box>
          <Stack spacing={2}>
            {!isEmpty(details?.remediation_instruction) ? (
              map(details?.remediation_instruction, o => (
                <Link color="primary" href={o.url} target="_blank">
                  <Text as={'u'}>{o.name}</Text>
                </Link>
              ))
            ) : (
              <Box>-</Box>
            )}
          </Stack>
        </Stack>
        {check.status !== 'Rule_Pass' && (
          <Stack spacing={3}>
            <Box fontWeight={600}>Remediation Instructions</Box>
            <Box
              mt={4}
              py={3}
              bg="gray.50"
              border="1px"
              borderColor="gray.100"
              borderRadius={5}
            >
              <Stack spacing={4}>
                <HStack spacing={5} px={4}>
                  <Center color={'red'} w={5}>
                    <ExclamationTriangleIcon />
                  </Center>
                  <H3>CAUTION</H3>
                </HStack>
                <Box
                  pl={14}
                  borderBottom="1px solid"
                  borderColor="gray.100"
                  pb={4}
                >
                  Please ensure that you follow corporate policies or security
                  workflows to carry out remediation for this item
                </Box>
                <HStack spacing={5} px={4}>
                  <Center color="primary" w={5}>
                    <ConsoleIcon />
                  </Center>
                  <H3>Steps for AWS console</H3>
                </HStack>

                {/*{!isEmpty(*/}
                {/*  remediation.data?.[check?.config_id]?.console_commands,*/}
                {/*) && (*/}
                {/*  <Box px={14}>*/}
                {/*    <AwsConsoleSteps*/}
                {/*      commands={*/}
                {/*        remediation.data?.[check?.config_id]?.console_commands*/}
                {/*      }*/}
                {/*    />*/}
                {/*  </Box>*/}
                {/*)}*/}

                <Box px={14}>
                  {isEmpty(
                    remediation.data?.[check?.config_id]?.console_commands
                      ?.steps,
                  ) ? (
                    <Stack>
                      <Box>
                        <Text pb={3}>Follow the link</Text>
                        <Link
                          color="primary"
                          href={
                            remediation.data?.[check?.config_id]
                              ?.console_commands?.console_path
                          }
                          target="_blank"
                        >
                          <Text as={'u'}>
                            {
                              remediation.data?.[check?.config_id]
                                ?.console_commands?.console_path
                            }
                          </Text>
                        </Link>
                      </Box>
                    </Stack>
                  ) : (
                    <OrderedList spacing={3}>
                      {map(
                        remediation.data?.[check?.config_id]?.console_commands
                          ?.steps,
                        o => (
                          <ListItem pl={4}>{o}</ListItem>
                        ),
                      )}
                    </OrderedList>
                  )}
                </Box>
              </Stack>
            </Box>
          </Stack>
        )}
      </Stack>
      {alertAction.isOpen && (
        <NotificationActions
          alert={{
            ...check,
            entity_name: account.name,
            entity_id: account.cloud_account_id,
            entity_uuid: check.resource_uuid,
            alert_rule_id: check.config_id,
          }}
          action={workflowType?.value}
          isOpen={alertAction.isOpen}
          onClose={alertAction.onClose}
          notification_for={NotificationFor.GovernanceChecklist}
        />
      )}
    </>
  );
};

//   alert_uuid: alert.uuid,
//   resource_id: alert.entity_id,
//   entity_uuid: alert.entity_uuid,
//   resource_type: alert.entity_type,
//   account_id: alert.account_id,
