import React, { FC, useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { successToast } from 'components/Toast';
import { useTrackApiCall } from 'hooks/useTrackApiCall';
import { useInjector } from 'utils/inject';

import { groupsSaga } from '../../Setup/Groups/saga';
import {
  reducer as groupReducer,
  sliceKey as groupSliceKey,
} from '../../Setup/Groups/slice';
import { setupUsersSaga } from '../../Setup/Users/saga';
import {
  reducer as userReducer,
  sliceKey as userSliceKey,
} from '../../Setup/Users/slice';

import { OrgDetails } from './OrgDetails';
import { organizationWizardSaga } from './saga';
import { selectOrganizationWizard } from './selectors';
import { actions, reducer, sliceKey } from './slice';

interface IEditOrg {}

export const EditOrg: FC<IEditOrg> = props => {
  useInjector(sliceKey, reducer, organizationWizardSaga);
  useInjector(groupSliceKey, groupReducer, groupsSaga);
  useInjector(userSliceKey, userReducer, setupUsersSaga);

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    updateOrganization,
    updateOrgUser,
    getOrganizationById,
  } = useSelector(selectOrganizationWizard);
  const updateApiCall = useTrackApiCall(updateOrganization);

  const handleReset = useCallback(() => {
    dispatch(actions.resetUpdateOrganization());
    history.push('/organizations');
  }, [dispatch, history]);

  const handleUpdateOrganization = () => {
    const { organization } = updateOrganization.data;
    // console.log(organization);
    // history.push('/organizations');
    dispatch(actions.updateOrganization(organization));
  };

  // check as per the type of the user form submitted
  const handleUpdateOrgUser = () => {};

  useEffect(() => {
    return () => {
      dispatch(actions.resetUpdateOrganization());
    };
  }, [dispatch]);

  useEffect(() => {
    if (updateApiCall.success) {
      successToast({
        title: 'Organization updated',
      });
      handleReset();
    }
  }, [handleReset, updateApiCall.success]);

  return (
    <OrgDetails
      action="Update"
      isLoading={updateOrganization.loading}
      isOrgDataLoading={
        getOrganizationById.loading || !getOrganizationById.loaded
      }
      organization={updateOrganization.data.organization}
      admin_user={updateOrgUser.data.new_user}
      updateOrgDetailsFieldAction={actions.updateUpdateOrganizationDetails}
      updateOrgUserDetailFieldAction={actions.updateUpdateNewOrgUserDetails}
      handleUserFormSubmit={handleUpdateOrganization}
      handleOrgFormSubmit={handleUpdateOrgUser}
      handleReset={handleReset}
    />
  );
};
