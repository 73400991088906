/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as fm from "../fetch.pb"
import * as GoogleProtobufTimestamp from "../../google/protobuf/timestamp.pb"

export enum OrganizationStatus {
  OrganizationStatusUnspecified = "OrganizationStatusUnspecified",
  Provisioning = "Provisioning",
  Provisioned = "Provisioned",
  Failed = "Failed",
  Deleting = "Deleting",
}

export enum Order {
  OrderUnspecified = "OrderUnspecified",
  Ascending = "Ascending",
  Descending = "Descending",
}

export enum OrganizationEvent {
  UnspecifiedEvent = "UnspecifiedEvent",
  Created = "Created",
  Disabled = "Disabled",
  Deleted = "Deleted",
}

export enum Topic {
  TopicUnspecified = "TopicUnspecified",
  OrganizationOnboarding = "OrganizationOnboarding",
}

export enum IdentityProviderName {
  Google = "Google",
  Okta = "Okta",
}

export type Organization = {
  id?: string
  name?: string
  address?: string
  city?: string
  zip_code?: string
  country?: string
  num_users?: string
  enabled?: boolean
  creation_time?: GoogleProtobufTimestamp.Timestamp
  full_name?: string
  admin_group_id?: string
  creator?: User
  num_admin_users?: string
  status?: OrganizationStatus
}

export type OrganizationServiceCreateRequest = {
  name?: string
  address?: string
  city?: string
  zip_code?: string
  country?: string
  admin_user?: NewUserParams
  enabled?: boolean
  full_name?: string
}

export type OrganizationServiceCreateResponse = {
  organization_id?: string
  organization_admin_group_id?: string
  admin_user_id?: string
}

export type OrganizationServiceGetRequest = {
  name?: string
}

export type OrganizationServiceGetResponse = {
  organization?: Organization
}

export type Page = {
  number?: string
  length?: string
}

export type OrganizationServiceListRequest = {
  sort_by?: string
  order?: Order
  page?: Page
}

export type OrganizationServiceListResponse = {
  organizations?: Organization[]
}

export type OrganizationServiceUpdateRequest = {
  name?: string
  address?: string
  city?: string
  zip_code?: string
  country?: string
  enabled?: boolean
  full_name?: string
}

export type OrganizationServiceUpdateResponse = {
}

export type OrganizationServiceDeleteRequest = {
  organization?: string
  force?: boolean
}

export type OrganizationServiceDeleteResponse = {
}

export type OrganizationServiceAddOfflineAccessRequest = {
}

export type OrganizationServiceAddOfflineAccessResponse = {
}

export type OrganizationNotification = {
  id?: string
  name?: string
  event?: OrganizationEvent
  timestamp?: string
}

export type Operation = {
  allowed?: boolean
  reason?: string
}

export type Operations = {
  delete?: Operation
}

export type User = {
  id?: string
  username?: string
  first_name?: string
  last_name?: string
  email?: string
  organization?: string
  enabled?: boolean
  roles?: Role[]
  user_groups?: UserGroup[]
  account_ids?: string[]
  creator?: User
  creation_time?: GoogleProtobufTimestamp.Timestamp
  operations?: Operations
  auto_add_accounts?: boolean
}

export type NewUserParams = {
  username?: string
  first_name?: string
  last_name?: string
  email?: string
  password?: string
  roles?: Role[]
  user_groups?: UserGroup[]
  account_ids?: string[]
  auto_add_accounts?: boolean
}

export type UpdateUserParams = {
  organization?: string
  id?: string
  first_name?: string
  last_name?: string
  email?: string
  enabled?: boolean
  roles?: Role[]
  user_groups?: UserGroup[]
  account_ids?: string[]
  auto_add_accounts?: boolean
}

export type UserServiceCreateRequest = {
  organization?: string
  user?: NewUserParams
}

export type UserServiceCreateResponse = {
  id?: string
}

export type UserServiceGetRequest = {
  id?: string
  organization?: string
}

export type UserServiceGetResponse = {
  user?: User
}

export type UserServiceGetByEmailRequest = {
  email?: string
  organization?: string
}

export type UserServiceGetByEmailResponse = {
  user?: User
}

export type UserServiceGetByUsernameRequest = {
  username?: string
  organization?: string
}

export type UserServiceGetByUsernameResponse = {
  user?: User
}

export type UserServiceListRequest = {
  organization?: string
}

export type UserServiceListResponse = {
  users?: User[]
}

export type UserServiceUpdateRequest = {
  user?: UpdateUserParams
}

export type UserServiceUpdateResponse = {
}

export type UserServiceDeleteRequest = {
  id?: string
  organization?: string
}

export type UserServiceDeleteResponse = {
}

export type UserGroup = {
  id?: string
  name?: string
  users?: User[]
  roles?: Role[]
  account_ids?: string[]
  admins?: User[]
  creator?: User
  creation_time?: GoogleProtobufTimestamp.Timestamp
  immutable?: boolean
  auto_add_accounts?: boolean
}

export type UserGroupServiceCreateRequest = {
  name?: string
  organization?: string
  admin_id?: string
  auto_add_accounts?: boolean
}

export type UserGroupServiceCreateResponse = {
}

export type UserGroupServiceGetRequest = {
  id?: string
  organization?: string
}

export type UserGroupServiceGetResponse = {
  user_group?: UserGroup
}

export type UserGroupServiceListRequest = {
  organization?: string
}

export type UserGroupServiceListResponse = {
  user_groups?: UserGroup[]
}

export type UserGroupServiceUpdateRequest = {
  organization?: string
  user_group?: UserGroup
}

export type UserGroupServiceUpdateResponse = {
}

export type UserGroupServiceDeleteRequest = {
  id?: string
  organization?: string
}

export type UserGroupServiceDeleteResponse = {
}

export type VerifyRequest = {
}

export type VerifyResponse = {
}

export type VerifyWSRequest = {
  token?: string
}

export type VerifyWSResponse = {
}

export type GetClientCredentialsRequest = {
}

export type GetClientCredentialsResponse = {
  client_id?: string
  client_secret?: string
}

export type Permissions = {
  create?: boolean
  view?: boolean
  update?: boolean
  delete?: boolean
}

export type Role = {
  id?: string
  name?: string
  system?: Permissions
  tenancy?: Permissions
  reporting?: Permissions
  remediation?: Permissions
  policy?: Permissions
  compliance?: Permissions
  resources?: string[]
  immutable?: boolean
  cloud_onboarding?: Permissions
  visibility?: Permissions
}

export type RoleServiceCreateRequest = {
  organization?: string
  role?: Role
}

export type RoleServiceCreateResponse = {
}

export type RoleServiceGetRequest = {
  organization?: string
  id?: string
}

export type RoleServiceGetResponse = {
  role?: Role
}

export type RoleServiceListRequest = {
  organization?: string
}

export type RoleServiceListResponse = {
  roles?: Role[]
}

export type RoleServiceUpdateRequest = {
  organization?: string
  role?: Role
}

export type RoleServiceUpdateResponse = {
}

export type RoleServiceDeleteRequest = {
  organization?: string
  id?: string
}

export type RoleServiceDeleteResponse = {
}

export type IdentityProviderNameConfig = {
  provider?: IdentityProviderName
  redirect_uri?: string
}

export type IdentityProviderNameServiceListRequest = {
  organization?: string
}

export type IdentityProviderNameServiceListResponse = {
  providers?: IdentityProviderNameConfig[]
}

export type IdentityProviderConfig = {
  client_id?: string
  client_secret?: string
  config_uri?: string
}

export type IdentityProvider = {
  provider?: IdentityProviderName
  enabled?: boolean
  config?: IdentityProviderConfig
}

export type IdentityProviderServiceListRequest = {
  organization?: string
}

export type IdentityProviderServiceListResponse = {
  identity_providers?: IdentityProvider[]
}

export type IdentityProviderServiceCreateRequest = {
  provider?: IdentityProviderName
  enabled?: boolean
  config?: IdentityProviderConfig
  organization?: string
}

export type IdentityProviderServiceCreateResponse = {
  identity_provider?: IdentityProvider
}

export type IdentityProviderServiceUpdateRequest = {
  identity_provider?: IdentityProvider
  organization?: string
}

export type IdentityProviderServiceUpdateResponse = {
  identity_provider?: IdentityProvider
}

export type IdentityProviderServiceDeleteRequest = {
  provider?: string
  organization?: string
}

export type IdentityProviderServiceDeleteResponse = {
}

export type IdentityProviderServiceSetupSSOAuthFlowRequest = {
}

export type IdentityProviderServiceSetupSSOAuthFlowResponse = {
}

export type Session = {
  ip_address?: string
  start_at?: string
  last_access?: string
}

export type UserAuthServiceListRequest = {
}

export type UserAuthServiceListResponse = {
  roles?: string[]
  accounts?: string[]
  created_at?: string
  sessions?: Session[]
}

export type OrganizationKey = {
  provider_id?: string
  provider_priority?: number
  kid?: string
  status?: string
  type?: string
  algorithm?: string
  public_key?: string
  certificate?: string
}

export class OrganizationService {
  static Create(req: OrganizationServiceCreateRequest, initReq?: fm.InitReq): Promise<OrganizationServiceCreateResponse> {
    return fm.fetchReq<OrganizationServiceCreateRequest, OrganizationServiceCreateResponse>(`/v1/auth/organization`, {...initReq, method: "POST", body: JSON.stringify(req)})
  }
  static Get(req: OrganizationServiceGetRequest, initReq?: fm.InitReq): Promise<OrganizationServiceGetResponse> {
    return fm.fetchReq<OrganizationServiceGetRequest, OrganizationServiceGetResponse>(`/v1/auth/organization/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static List(req: OrganizationServiceListRequest, initReq?: fm.InitReq): Promise<OrganizationServiceListResponse> {
    return fm.fetchReq<OrganizationServiceListRequest, OrganizationServiceListResponse>(`/v1/auth/organization?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static Update(req: OrganizationServiceUpdateRequest, initReq?: fm.InitReq): Promise<OrganizationServiceUpdateResponse> {
    return fm.fetchReq<OrganizationServiceUpdateRequest, OrganizationServiceUpdateResponse>(`/v1/auth/organization`, {...initReq, method: "PUT", body: JSON.stringify(req)})
  }
  static Delete(req: OrganizationServiceDeleteRequest, initReq?: fm.InitReq): Promise<OrganizationServiceDeleteResponse> {
    return fm.fetchReq<OrganizationServiceDeleteRequest, OrganizationServiceDeleteResponse>(`/v1/auth/organization/${req["organization"]}`, {...initReq, method: "DELETE"})
  }
  static AddOfflineAccess(req: OrganizationServiceAddOfflineAccessRequest, initReq?: fm.InitReq): Promise<OrganizationServiceAddOfflineAccessResponse> {
    return fm.fetchReq<OrganizationServiceAddOfflineAccessRequest, OrganizationServiceAddOfflineAccessResponse>(`/v1/auth/organization/offline-access`, {...initReq, method: "PUT", body: JSON.stringify(req)})
  }
}
export class UserService {
  static Create(req: UserServiceCreateRequest, initReq?: fm.InitReq): Promise<UserServiceCreateResponse> {
    return fm.fetchReq<UserServiceCreateRequest, UserServiceCreateResponse>(`/v1/auth/user`, {...initReq, method: "POST", body: JSON.stringify(req)})
  }
  static Get(req: UserServiceGetRequest, initReq?: fm.InitReq): Promise<UserServiceGetResponse> {
    return fm.fetchReq<UserServiceGetRequest, UserServiceGetResponse>(`/v1/auth/user/${req["id"]}?${fm.renderURLSearchParams(req, ["id"])}`, {...initReq, method: "GET"})
  }
  static GetByEmail(req: UserServiceGetByEmailRequest, initReq?: fm.InitReq): Promise<UserServiceGetByEmailResponse> {
    return fm.fetchReq<UserServiceGetByEmailRequest, UserServiceGetByEmailResponse>(`/v1/auth/user-by-email/${req["email"]}?${fm.renderURLSearchParams(req, ["email"])}`, {...initReq, method: "GET"})
  }
  static GetByUsername(req: UserServiceGetByUsernameRequest, initReq?: fm.InitReq): Promise<UserServiceGetByUsernameResponse> {
    return fm.fetchReq<UserServiceGetByUsernameRequest, UserServiceGetByUsernameResponse>(`/v1/auth/user-by-username/${req["username"]}?${fm.renderURLSearchParams(req, ["username"])}`, {...initReq, method: "GET"})
  }
  static List(req: UserServiceListRequest, initReq?: fm.InitReq): Promise<UserServiceListResponse> {
    return fm.fetchReq<UserServiceListRequest, UserServiceListResponse>(`/v1/auth/user?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static Update(req: UserServiceUpdateRequest, initReq?: fm.InitReq): Promise<UserServiceUpdateResponse> {
    return fm.fetchReq<UserServiceUpdateRequest, UserServiceUpdateResponse>(`/v1/auth/user`, {...initReq, method: "PUT", body: JSON.stringify(req)})
  }
  static Delete(req: UserServiceDeleteRequest, initReq?: fm.InitReq): Promise<UserServiceDeleteResponse> {
    return fm.fetchReq<UserServiceDeleteRequest, UserServiceDeleteResponse>(`/v1/auth/user/${req["id"]}`, {...initReq, method: "DELETE", body: JSON.stringify(req)})
  }
}
export class UserGroupService {
  static Create(req: UserGroupServiceCreateRequest, initReq?: fm.InitReq): Promise<UserGroupServiceCreateResponse> {
    return fm.fetchReq<UserGroupServiceCreateRequest, UserGroupServiceCreateResponse>(`/v1/auth/usergroup`, {...initReq, method: "POST", body: JSON.stringify(req)})
  }
  static Get(req: UserGroupServiceGetRequest, initReq?: fm.InitReq): Promise<UserGroupServiceGetResponse> {
    return fm.fetchReq<UserGroupServiceGetRequest, UserGroupServiceGetResponse>(`/v1/auth/usergroup/${req["id"]}?${fm.renderURLSearchParams(req, ["id"])}`, {...initReq, method: "GET"})
  }
  static List(req: UserGroupServiceListRequest, initReq?: fm.InitReq): Promise<UserGroupServiceListResponse> {
    return fm.fetchReq<UserGroupServiceListRequest, UserGroupServiceListResponse>(`/v1/auth/usergroup?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static Update(req: UserGroupServiceUpdateRequest, initReq?: fm.InitReq): Promise<UserGroupServiceUpdateResponse> {
    return fm.fetchReq<UserGroupServiceUpdateRequest, UserGroupServiceUpdateResponse>(`/v1/auth/usergroup`, {...initReq, method: "PUT", body: JSON.stringify(req)})
  }
  static Delete(req: UserGroupServiceDeleteRequest, initReq?: fm.InitReq): Promise<UserGroupServiceDeleteResponse> {
    return fm.fetchReq<UserGroupServiceDeleteRequest, UserGroupServiceDeleteResponse>(`/v1/auth/usergroup/${req["id"]}`, {...initReq, method: "DELETE", body: JSON.stringify(req)})
  }
}
export class VerifyService {
  static Verify(req: VerifyRequest, initReq?: fm.InitReq): Promise<VerifyResponse> {
    return fm.fetchReq<VerifyRequest, VerifyResponse>(`/v1/auth/verify?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static VerifyWS(req: VerifyWSRequest, initReq?: fm.InitReq): Promise<VerifyWSResponse> {
    return fm.fetchReq<VerifyWSRequest, VerifyWSResponse>(`/v1/auth/verify-ws?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static GetClientCredentials(req: GetClientCredentialsRequest, initReq?: fm.InitReq): Promise<GetClientCredentialsResponse> {
    return fm.fetchReq<GetClientCredentialsRequest, GetClientCredentialsResponse>(`/v1/auth/client-credentials?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
}
export class RoleService {
  static Create(req: RoleServiceCreateRequest, initReq?: fm.InitReq): Promise<RoleServiceCreateResponse> {
    return fm.fetchReq<RoleServiceCreateRequest, RoleServiceCreateResponse>(`/v1/auth/role`, {...initReq, method: "POST", body: JSON.stringify(req)})
  }
  static Get(req: RoleServiceGetRequest, initReq?: fm.InitReq): Promise<RoleServiceGetResponse> {
    return fm.fetchReq<RoleServiceGetRequest, RoleServiceGetResponse>(`/v1/auth/role/${req["id"]}?${fm.renderURLSearchParams(req, ["id"])}`, {...initReq, method: "GET"})
  }
  static List(req: RoleServiceListRequest, initReq?: fm.InitReq): Promise<RoleServiceListResponse> {
    return fm.fetchReq<RoleServiceListRequest, RoleServiceListResponse>(`/v1/auth/role?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static Update(req: RoleServiceUpdateRequest, initReq?: fm.InitReq): Promise<RoleServiceUpdateResponse> {
    return fm.fetchReq<RoleServiceUpdateRequest, RoleServiceUpdateResponse>(`/v1/auth/role`, {...initReq, method: "PUT", body: JSON.stringify(req)})
  }
  static Delete(req: RoleServiceDeleteRequest, initReq?: fm.InitReq): Promise<RoleServiceDeleteResponse> {
    return fm.fetchReq<RoleServiceDeleteRequest, RoleServiceDeleteResponse>(`/v1/auth/role/${req["id"]}`, {...initReq, method: "DELETE", body: JSON.stringify(req)})
  }
}
export class IdentityProviderNameService {
  static List(req: IdentityProviderNameServiceListRequest, initReq?: fm.InitReq): Promise<IdentityProviderNameServiceListResponse> {
    return fm.fetchReq<IdentityProviderNameServiceListRequest, IdentityProviderNameServiceListResponse>(`/v1/auth/identity-provider-names?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
}
export class IdentityProviderService {
  static Create(req: IdentityProviderServiceCreateRequest, initReq?: fm.InitReq): Promise<IdentityProviderServiceCreateResponse> {
    return fm.fetchReq<IdentityProviderServiceCreateRequest, IdentityProviderServiceCreateResponse>(`/v1/auth/identity-provider`, {...initReq, method: "POST", body: JSON.stringify(req)})
  }
  static List(req: IdentityProviderServiceListRequest, initReq?: fm.InitReq): Promise<IdentityProviderServiceListResponse> {
    return fm.fetchReq<IdentityProviderServiceListRequest, IdentityProviderServiceListResponse>(`/v1/auth/identity-provider?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static Update(req: IdentityProviderServiceUpdateRequest, initReq?: fm.InitReq): Promise<IdentityProviderServiceUpdateResponse> {
    return fm.fetchReq<IdentityProviderServiceUpdateRequest, IdentityProviderServiceUpdateResponse>(`/v1/auth/identity-provider`, {...initReq, method: "PUT", body: JSON.stringify(req)})
  }
  static Delete(req: IdentityProviderServiceDeleteRequest, initReq?: fm.InitReq): Promise<IdentityProviderServiceDeleteResponse> {
    return fm.fetchReq<IdentityProviderServiceDeleteRequest, IdentityProviderServiceDeleteResponse>(`/v1/auth/identity-provider`, {...initReq, method: "DELETE", body: JSON.stringify(req)})
  }
  static SetupSSOAuthFlow(req: IdentityProviderServiceSetupSSOAuthFlowRequest, initReq?: fm.InitReq): Promise<IdentityProviderServiceSetupSSOAuthFlowResponse> {
    return fm.fetchReq<IdentityProviderServiceSetupSSOAuthFlowRequest, IdentityProviderServiceSetupSSOAuthFlowResponse>(`/v1/auth/sso-auth-flow`, {...initReq, method: "PUT", body: JSON.stringify(req)})
  }
}
export class UserAuthService {
  static List(req: UserAuthServiceListRequest, initReq?: fm.InitReq): Promise<UserAuthServiceListResponse> {
    return fm.fetchReq<UserAuthServiceListRequest, UserAuthServiceListResponse>(`/v1/auth/user-auth?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
}
