import React, { FC } from 'react';

import { SharedStateContext } from 'containers/SharedState/context/context';
import { useInjector } from 'utils/inject';

import { sharedStateSaga } from '../saga';
import { reducer, sliceKey } from '../slice';

export const SharedStateProvider: FC = props => {
  useInjector(sliceKey, reducer, sharedStateSaga);

  const { children } = props;

  return (
    <SharedStateContext.Provider value={{}}>
      {children}
    </SharedStateContext.Provider>
  );
};
