import React, { FC } from 'react';

import { Box, Center, Flex, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';

import { PrimaryButton } from 'components/DataEntry/Button';
import { contentStyles } from 'components/FeatureDemo/styles';
import {
  AnomalousBehaviourIcon,
  AriksaLogoIcon,
  DatabaseIcon,
  GlobeIcon,
  UserIcon,
} from 'components/Icons';

import { customTheme } from '../../../../theme';

interface IOverview {
  next();
}

export const Overview: FC<IOverview> = props => {
  const list = [
    {
      header: 'Infrastructure security & exposure',
      description:
        'Detect and remediate combinations of toxic misconfigurations and ' +
        'suspicious activity that indicate exposure or attack attempts to exfiltrate data',
      icon: <GlobeIcon />,
    },
    {
      header: 'App vulnerability exploitation',
      description:
        'Manage exploitable vulnerabilities for apps in containers and VMs ' +
        'that can create attack paths and threats to sensitive business environment and data',
      icon: <AnomalousBehaviourIcon p={1} />,
    },
    {
      header: 'Access & governance',
      description:
        'Uncover user & machine over privileges to data & other resources, ' +
        'inactive identities, app access to data and TTPs such as privilege escalation and lateral movement',
      icon: <UserIcon p={1} />,
    },
    {
      header: 'Data security and data classification',
      description:
        'Complete visibility into data footprint. Automated classification, ' +
        'and sensitivity and risk analyses of data sources for robust access management',
      icon: <DatabaseIcon p={1} />,
    },
  ];

  return (
    <Stack {...contentStyles.root}>
      <Flex {...contentStyles.logoWrapper}>
        {/* Logo */}
        <Center my="auto" {...contentStyles.logo}>
          <AriksaLogoIcon />
        </Center>
      </Flex>
      <Stack {...contentStyles.content}>
        <Box>
          Ariksa provides holistic security that brings elevated levels of
          automation and intelligence to help you detect, investigate and
          respond to threats efficiently. Ariksa helps prioritize critical
          issues that impact your cloud and business instead of buying and
          managing more tools!
        </Box>
        <HStack justify="space-between">
          {map(list, (o, index) => (
            <Stack spacing={0} h="full" key={index + '-key-feature-demo'}>
              <Center>
                <Box
                  boxSize={8}
                  position="absolute"
                  borderRadius="full"
                  bg="white"
                />
              </Center>
              <Center w="full" position="relative">
                <Box
                  boxSize={12}
                  bg="white"
                  color="critical"
                  p={2}
                  position="absolute"
                  borderRadius="full"
                  border="1px solid"
                  borderColor="primary"
                >
                  <Center>{o?.icon}</Center>
                </Box>
              </Center>
              <Box
                w={60}
                h="full"
                border="1px solid"
                borderColor="primary"
                borderRadius={6}
                bg="white"
                overflow="visible"
                pt={8}
                pb={6}
                px={8}
                boxShadow={`1px 6px 6px ${customTheme.colors.gray['100']}`}
              >
                <Stack spacing={2} w="full">
                  <Box
                    fontWeight={600}
                    fontSize="md"
                    color="primary"
                    w="full"
                    px={2}
                  >
                    <Flex w="full" textAlign="center">
                      {o.header}...
                    </Flex>
                  </Box>
                  <Box borderBottom="1px solid" borderColor="primary" />
                  <Box fontWeight={600} fontSize="sm">
                    {o?.description}
                  </Box>
                </Stack>
              </Box>
            </Stack>
          ))}
        </HStack>
        <Flex {...contentStyles.buttonGroup}>
          <PrimaryButton {...contentStyles.button} onClick={props.next}>
            NEXT
          </PrimaryButton>
        </Flex>
      </Stack>
    </Stack>
  );
};
