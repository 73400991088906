import { useCallback } from 'react';

import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectCloudAccounts } from 'containers/Setup/CloudAccounts/selectors';
import { actions } from 'containers/Setup/CloudAccounts/slice';
import { actions as sharedStateActions } from 'containers/SharedState/slice';

export const useCloudAccountsFunctions = () => {
  const { accounts, memberAccounts } = useSelector(selectCloudAccounts);
  const dispatch = useDispatch();
  const history = useHistory();

  const getInformation = useCallback(
    accounts => {
      const accountIds = map(accounts, o => o.uuid);
      dispatch(actions.getVulnerabilityScanner({}));
      dispatch(
        actions.getDataScannerInfo({
          q: { accountsIds: accountIds },
        }),
      );
      dispatch(
        sharedStateActions.getSnapshotStatus({
          q: { getLatestWorkflowRequest: { account_ids: accountIds } },
        }),
      );
    },
    [dispatch],
  );

  const getCloudAccounts = useCallback(() => {
    dispatch(
      actions.getCloudAccounts({
        q: {
          page: accounts.page.info.page_number,
          size: accounts.page.info.page_size,
        },
        onSuccess: res => {
          // if no cloud account is onboarded redirect to add account page
          if (!res?.total) {
            // history.push('/setup/accounts/add/aws');
          } else {
            getInformation(res?.items);
          }
        },
      }),
    );
  }, [accounts.page.info, dispatch, history, getInformation]);

  const getMemberAccounts = useCallback(
    managementAccountId => {
      dispatch(
        actions.getMemberAccounts({
          q: {
            accountUuid: managementAccountId,
            page: memberAccounts.page.info.page_number,
            size: memberAccounts.page.info.page_size,
          },
          onSuccess: res => getInformation(res?.items),
        }),
      );
    },
    [dispatch, memberAccounts.page.info, getInformation],
  );

  return {
    getCloudAccounts,
    getMemberAccounts,
  };
};
