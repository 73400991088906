import React, { BaseSyntheticEvent, FC, ReactNode, useMemo } from 'react';

import { Flex, FlexProps } from '@chakra-ui/react';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import { defaultCardStyle } from './styles';

export interface ICardStyles {
  card?: FlexProps;
  cardContent?: FlexProps;
  header?: FlexProps;
}

export interface ICard {
  headerComponent?: ReactNode;
  contentComponent?: ReactNode;
  footerComponent?: ReactNode;

  onClick?: (record?: any, e?: BaseSyntheticEvent) => void;
  record?: any;
  styles?: ICardStyles;
  overwriteDefaultStyles?: boolean;
}

export const Card: FC<ICard> = props => {
  const {
    headerComponent,
    contentComponent,
    footerComponent,
    onClick,
    record,
    styles,
    overwriteDefaultStyles,
  } = props;

  const baseStyles = useMemo(() => {
    const props = cloneDeep(defaultCardStyle);
    return overwriteDefaultStyles ? styles : merge(props, styles);
  }, [styles, overwriteDefaultStyles]);

  // const boxShadow = useColorModeValue('0 0 30px #e4e4e4', 'none');

  const handleOnClick = React.useCallback(
    (e: BaseSyntheticEvent) => {
      onClick?.(record, e);
    },
    [record, onClick],
  );

  return (
    <Flex
      className={'card'}
      pt={headerComponent ? 0 : 4}
      onClick={handleOnClick}
      overflow="auto"
      {...baseStyles?.card}
    >
      {headerComponent && (
        <Flex {...baseStyles?.header}>{headerComponent}</Flex>
      )}

      <Flex {...baseStyles?.cardContent}>
        {!!contentComponent ? contentComponent : props.children}
      </Flex>

      {footerComponent && footerComponent}
    </Flex>
  );
};
