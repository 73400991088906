import React, { memo, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Switch } from 'react-router-dom';

import { FilterRouter } from 'components/FilterView';
import AIAssistant from 'containers/ConversationalAI/Components/AiAssistant';
import { ConversationalAILandingPage } from 'containers/ConversationalAI/Components/ConversationalAILandingPage';
import { useInjector } from 'utils/inject';

import { conversationalAISaga } from './saga';
import { actions, reducer, sliceKey } from './slice';

export const ConversationalAI = memo(() => {
  useInjector(sliceKey, reducer, conversationalAISaga);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getCloudInsight({ q: { accountId: [] } }));
  }, [dispatch]);

  //my tasks
  useEffect(() => {
    dispatch(actions.getEOLSoftware({ q: { size: 200 } }));
  }, [dispatch]);

  return (
    <Switch>
      <FilterRouter path="/home/ai-assistant" component={AIAssistant} />
      <FilterRouter path="/home" component={ConversationalAILandingPage} />
    </Switch>
  );
});
