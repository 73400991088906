import {
  CloudAccountApiGetCloudAccountsRequest,
  PageCloudAccountGetResponse,
} from '@ariksa/cloud-account/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { authOptions } from 'api/auth';
import {
  UserGroupService,
  UserGroupServiceCreateRequest,
  UserGroupServiceCreateResponse,
  UserGroupServiceGetRequest,
  UserGroupServiceListRequest,
  UserGroupServiceListResponse,
  UserGroupServiceUpdateRequest,
} from 'api/auth/api.pb';
import { CloudAccountService } from 'api/services';
import { errorToast } from 'components/Toast';
import { selectUser } from 'containers/App/selectors';
import { callApiFn } from 'utils/saga';

import { actions } from './slice';

export function* groupsSaga() {
  yield takeLatestAction(actions.createGroup, doCreateGroup);
  yield takeLatest(actions.loadGroups.type, doGetGroups);
  yield takeLatest(actions.getGroupById.type, doGetGroupById);
  yield takeLatest(actions.updateGroup.type, doUpdateGroup);
  yield takeLatest(actions.deleteGroup.type, doDeleteGroup);
  yield takeLatestAction(actions.getCloudAccounts, doGetCloudAccounts);
}

export function* doUpdateGroup(
  action: PayloadAction<UserGroupServiceUpdateRequest>,
) {
  try {
    const res = yield call(
      UserGroupService.Update,
      action.payload,
      authOptions(),
    );
    yield put(actions.updateGroupSuccess(res));
  } catch (err) {
    errorToast({
      title: err.message,
    });
    yield put(actions.updateGroupError(err));
  }
}

export function* doGetCloudAccounts(
  ctx: QueryContext<
    PageCloudAccountGetResponse,
    CloudAccountApiGetCloudAccountsRequest
  >,
) {
  yield call(ctx.fetch, () =>
    CloudAccountService.CloudAccount.getCloudAccounts({
      ...ctx.params,
      page: ctx.page.page_number,
      size: ctx.page.page_size,
    }),
  );
}

export function* doGetGroupById(
  action: PayloadAction<UserGroupServiceGetRequest>,
) {
  try {
    const res = yield call(UserGroupService.Get, action.payload, authOptions());
    yield put(actions.getGroupByIdSuccess(res));
  } catch (err) {
    yield put(actions.getGroupByIdError(err));
  }
}

export function* doCreateGroup(
  ctx: QueryContext<
    UserGroupServiceCreateResponse,
    UserGroupServiceCreateRequest
  >,
) {
  yield call(ctx.fetch, () =>
    UserGroupService.Create(ctx.params, authOptions()),
  );
}

export function* doGetGroups(
  action: PayloadAction<UserGroupServiceListRequest>,
) {
  try {
    const res: UserGroupServiceListResponse = yield call(
      UserGroupService.List,
      action.payload,
      authOptions(),
    );
    yield put(actions.loadGroupsSuccess(res));
  } catch (err) {
    // console.log(err);
    yield put(actions.loadGroupsError(err));
  }
}

export function* doDeleteGroup(action: PayloadAction<any>) {
  const user = yield select(selectUser);
  const data = {
    id: action.payload.id,
    organization: user.info.org_id,
  };

  yield call(callApiFn, {
    fn: UserGroupService.Delete,
    data,
    onSuccess: actions.deleteGroupSuccess,
    onError: actions.deleteGroupError,
  });
}
