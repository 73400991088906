import React, { FC } from 'react';

import { Box, useDisclosure, HStack, Center } from '@chakra-ui/react';
import { DayPicker } from 'react-day-picker';

import { dateLabel, H4 } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { PrimaryButton, PrimaryIconButton } from 'components/DataEntry';
import { CustomDayPickerProps } from 'components/DataEntry/Date/types';
import { CalendarIcon } from 'components/Icons';
import { Popover } from 'components/Overlay';

import { defaultStyles } from './styles';

export const CustomDayPicker: FC<CustomDayPickerProps> = props => {
  const {
    label,
    button,
    dateProps,
    styles,
    isLoading = false,
    portal = false,
    showValue = '',
    iconButton = false,
    header,
    footer,
    popoverDisclosure,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getValue = () => {
    const mode = dateProps?.mode;
    if (!dateProps?.selected) return <Box color="gray.200">Choose date</Box>;
    if (showValue) return showValue;
    switch (mode) {
      case 'single':
        return dateLabel(dateProps?.selected);
      case 'multiple':
        return dateProps?.selected?.length;
      case 'range':
        console.log(dateProps?.selected);
        return '';
    }
  };
  return (
    <Box>
      <Popover
        styles={{ footer: { p: 0 }, ...styles?.popover }}
        isOpen={popoverDisclosure?.isOpen || isOpen}
        onClose={popoverDisclosure?.onClose ?? onClose}
        portal={portal}
        button={
          iconButton ? (
            <Box>
              <PrimaryIconButton
                aria-label="filters"
                onClick={popoverDisclosure?.onOpen ?? onOpen}
                icon={<CalendarIcon />}
                tooltip={label}
              />
            </Box>
          ) : button ? (
            <PrimaryButton onClick={popoverDisclosure?.onOpen ?? onOpen}>
              {label ?? 'Select Date'}
            </PrimaryButton>
          ) : (
            <Box
              onClick={popoverDisclosure?.onOpen ?? onOpen}
              {...defaultStyles.inputBox}
              {...styles?.inputBox}
              py={1}
            >
              <HStack>
                <Center boxSize={5}>
                  {isLoading ? (
                    <CustomSpinner loading={isLoading} />
                  ) : (
                    <CalendarIcon />
                  )}
                </Center>
                <Box>{getValue()}</Box>
              </HStack>
            </Box>
          )
        }
        header={header ?? <H4 outline={'none'}>Select date</H4>}
        footer={footer}
        body={
          <Box>
            <DayPicker showOutsideDays styles={{}} {...dateProps} />
          </Box>
        }
      />
    </Box>
  );
};
