/**
 *
 * Page header with icon
 *
 */

import React, { FC, ReactNode } from 'react';

import { Stack, Box, Heading, BoxProps } from '@chakra-ui/react';

import { AriksaIcon } from 'components/DataDisplay';
import { IconSize, IconType } from 'components/Icons';

export interface PageHeaderWithIconProps {
  label: string | ReactNode;
  icon?: ReactNode;
  iconBgColor?: string;
  iconType?: IconType;
  iconFilled?: boolean;
  reversed?: boolean;
  useCustomColor?: boolean;
  fontSize?: any;
  fontColor?: string;
  iconColor?: string;
  spacing?: any;
  iconSize?: IconSize;
  labelWidth?: string | number;
  border?: boolean;
  borderColor?: string;
  onClickIcon?();
  labelStyles?: any;
  iconStyles?: BoxProps;
}

export const PageHeaderWithIcon: FC<PageHeaderWithIconProps> = props => {
  const {
    iconBgColor = 'primary',
    label,
    reversed = true,
    icon,
    iconType,
    fontSize = 'lg',
    spacing = 4,
    iconSize = 'small',
    labelWidth,
    fontColor,
    iconColor,
    onClickIcon,
    labelStyles,
    iconStyles,
    ...rest
  } = props;

  const renderIcon = () => (
    <AriksaIcon
      bg={iconBgColor}
      size={iconSize}
      icon={icon}
      type={iconType}
      color={iconColor}
      onClick={onClickIcon}
      style={iconStyles}
      {...rest}
    />
  );

  const renderLabel = () => (
    <Heading
      fontSize={fontSize}
      w={labelWidth}
      color={fontColor}
      {...labelStyles}
    >
      {label}
    </Heading>
  );

  return (
    <Stack isInline h="full" align="center" spacing={spacing}>
      {reversed ? (
        <>
          {(icon || iconType) && renderIcon()}
          {renderLabel()}
        </>
      ) : (
        <>
          {renderLabel()}
          {(icon || iconType) && (
            <Box borderRight="1px solid" borderColor="gray.600" h="20px" />
          )}
          {(icon || iconType) && renderIcon()}
        </>
      )}
    </Stack>
  );
};
