import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { APIPath, VERB } from '@ariksa/ai-engine';
import { Box, Center, HStack, useDisclosure } from '@chakra-ui/react';
import { isArray, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { renderTime } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { SyncRestartIcon } from 'components/Icons/ReactCustomIcons/SyncRestartIcon';
import { SecondaryNavBarRightComponent } from 'components/Navigation/NavBar/SecondaryNavBar/SecondaryNavBarRightComponent';
import { useMyTasks } from 'components/Navigation/NavBar/TopNavBar/RightComponent/MyTasks/useMyTasks';
import { useAlertNotification } from 'containers/App/hooks/notification/useAlertNotification';
import { useUserPermission } from 'containers/App/hooks/permissions/useUserPermission';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { useKeycloakContext } from 'containers/App/keycloak/context';
import { NavBarWrapper } from 'containers/App/NavBarWrapper';
import { selectApp } from 'containers/App/selectors';
import { actions } from 'containers/App/slice';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions as sharedStateActions } from 'containers/SharedState/slice';

interface IAppNav {
  isOpenWelcomeScreen: boolean;

  onOpenCloudInsight(): void;
}

export const AppNav: FC<IAppNav> = props => {
  const { onOpenCloudInsight } = props;
  const dispatch = useDispatch();

  const app = useSelector(selectApp);
  const { cloudAccounts, user, environments } = app;
  const {
    lastRefreshed,
    faq,
    aiParamExtraction,
    journey,
    searchTerm,
  } = useSelector(selectSharedState);

  const aiSearchModal = useDisclosure();

  const { environmentId, accountId } = useAccessBoundary();

  const { resetUserPermission } = useUserPermission();
  const { logOut, resetPassword } = useKeycloakContext();

  const [faqSearchTerm, setFaqSearchTerm] = useState('');
  useAlertNotification();

  const { getMyTasks, getAllTasksStatus } = useMyTasks();

  /*load the cloud accounts*/
  useEffect(() => {
    if (user.authenticated) {
      dispatch(actions.loadResourceTypes({}));
      dispatch(actions.loadEnvAndAccounts());
      dispatch(sharedStateActions.getBlueprints({ q: {} }));
    }
  }, [dispatch, user.authenticated]);

  const loadLastRefreshed = useCallback(() => {
    dispatch(
      sharedStateActions.getLastDiscoveryTime({
        q: {
          getLatestWorkflowRequest: {
            environment_id: environmentId,
            account_ids: accountId ? [accountId] : [],
          },
        },
      }),
    );
  }, [dispatch, environmentId, accountId]);

  useEffect(() => {
    getMyTasks();
  }, [getMyTasks]);

  useEffect(() => {
    loadLastRefreshed();
    const interval = setInterval(() => {
      loadLastRefreshed();
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, [loadLastRefreshed]);

  useEffect(() => {
    dispatch(sharedStateActions.getFAQ({ q: { searchQuery: faqSearchTerm } }));
  }, [dispatch, faqSearchTerm]);

  const handleLogOut = e => {
    e.preventDefault();
    e.stopPropagation();
    resetUserPermission();
    logOut();
    return false;
  };

  const onSelectEnvironmentOrAccount = useCallback(
    selected => {
      dispatch(actions.setAccount(selected));
    },
    [dispatch],
  );

  const filteredEnvironments = useMemo(() => {
    if (!isArray(environments.data)) return [];
    return environments.data.filter(env => {
      return Array.from(env.account_ids ?? []).some(accountId =>
        cloudAccounts.data.some(account => account.uuid === accountId),
      );
    });
  }, [cloudAccounts.data, environments.data]);

  // accounts the user have access to
  const filteredAccounts = useMemo(() => {
    const accountIds = user.info.account_ids;
    return cloudAccounts.data.filter(account => {
      return accountIds?.includes(account.uuid);
    });
  }, [cloudAccounts.data, user.info.account_ids]);

  return (
    <>
      <SecondaryNavBarRightComponent.Source>
        <HStack align="flex-start">
          <Center h="full">
            <Center boxSize={5}>
              <SyncRestartIcon />
            </Center>
          </Center>
          <HStack color="gray.250" fontSize="xs" h="full">
            <Box>Last Sync:</Box>
            <Box>
              {lastRefreshed.isLoading ? (
                <CustomSpinner loading={lastRefreshed.isLoading} />
              ) : (
                renderTime({ value: lastRefreshed.data })
              )}
            </Box>
          </HStack>
        </HStack>
      </SecondaryNavBarRightComponent.Source>
      {user.authenticated && user.info.id && (
        <NavBarWrapper
          showWelcomeScreen={props.isOpenWelcomeScreen}
          environments={{
            loading: environments.isLoading,
            loaded: environments.isSuccess,
            data: filteredEnvironments,
          }}
          accounts={{
            loading: cloudAccounts.loading,
            loaded: cloudAccounts.loaded,
            data: filteredAccounts,
          }}
          alerts={[]}
          onLogout={handleLogOut}
          resetPassword={resetPassword}
          user={user}
          onSelectEnvironmentOrAccount={onSelectEnvironmentOrAccount}
          faq={{
            data: faq.data,
            isLoading: faq.isLoading,
            searchTerm: faqSearchTerm,
            setSearchTerm: setFaqSearchTerm,
          }}
          journey={{
            myTasks: map(journey.myTasks.data, o => o),
            taskRead: journey.taskStatus,
            isLoadingMyTasks: journey.myTasks.isLoading,
            getMyTasksStatus: getAllTasksStatus,
          }}
          search={{
            value: searchTerm,
            aiSearchModal: aiSearchModal,
            onAiSearch: searchTerm => {
              console.log('SearchTerm', searchTerm);

              dispatch(sharedStateActions.updateSearchTerm(searchTerm));
              dispatch(
                sharedStateActions.extractParametersForSearch({
                  q: {
                    q: searchTerm,
                    apiPath: APIPath.NotificationAlertsAggregatedAlerts,
                    verb: VERB.Get,
                  },
                  onSuccess: () => {
                    aiSearchModal.onClose();
                  },
                  onError: () => {
                    aiSearchModal.onClose();
                  },
                }),
              );
            },
            onSearch: searchTerm => {
              dispatch(sharedStateActions.updateSearchTerm(searchTerm));
              dispatch(sharedStateActions.updateFinalSearchTerm(searchTerm));
              aiSearchModal.onClose();

              if (!searchTerm) {
                dispatch(sharedStateActions.updateSearchTerm(''));
                dispatch(sharedStateActions.updateFinalSearchTerm(''));
                dispatch(sharedStateActions.resetAIParamExtraction());
              }
            },
            onClearSearch: () => {
              dispatch(sharedStateActions.updateSearchTerm(''));
              dispatch(sharedStateActions.updateFinalSearchTerm(''));
              dispatch(sharedStateActions.resetAIParamExtraction());
            },
            onChangeSearchTerm: value => {
              dispatch(sharedStateActions.updateSearchTerm(value));
              if (!value) {
                dispatch(sharedStateActions.updateSearchTerm(''));
                dispatch(sharedStateActions.updateFinalSearchTerm(''));
                dispatch(sharedStateActions.resetAIParamExtraction());
              }
            },
            isLoading: aiParamExtraction.isLoading,
          }}
          cloudInsight={{
            onOpen: onOpenCloudInsight,
          }}
        />
      )}
    </>
  );
};
