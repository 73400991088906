import React, { FC } from 'react';

import { Route, Switch } from 'react-router-dom';

import { AuthCallback } from 'containers/Authentication/AuthCallback';
import { Login } from 'containers/Authentication/Login';
import { LoginOrganization } from 'containers/Authentication/LoginOrganization';

interface IAuthentication {}

export const Authentication: FC<IAuthentication> = props => {
  console.log('authentication');
  return (
    <Switch>
      <Route path={'/auth/login'} component={Login} />
      <Route path={'/auth'} component={LoginOrganization} />
    </Switch>
  );
};
