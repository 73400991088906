import React, { useState } from 'react';

import { Stack, Flex, useDisclosure, Square } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToggle } from 'react-use';

import { AIBrainIcon } from 'components/Icons/ReactCustomIcons/AIBrainIcon';
import { PageContent } from 'components/Layout/PageContent';
import { AiBrainButton } from 'containers/App/AiBrainButton';
import { AppNav } from 'containers/App/AppNav';
import { AppRoutes } from 'containers/App/AppRoutes';
import { CloudInsightDrawer } from 'containers/App/CloudInsight';
import { useUserPermission } from 'containers/App/hooks/permissions/useUserPermission';
import { KeycloakContext } from 'containers/App/keycloak/KeycloakContext';
import { alertsSaga } from 'containers/Findings/Alerts/saga';
import * as alerts from 'containers/Findings/Alerts/slice';
import { queryFilterSaga } from 'containers/QueryFilter/saga';
import * as queryFilter from 'containers/QueryFilter/slice';
import { SharedStateProvider } from 'containers/SharedState/context/SharedStateProvider';
import { useInjector } from 'utils/inject';

import {
  LeftArrowIcon,
  PrimaryIconButton,
  RightArrowIcon,
  UserPermissionsProvider,
} from '../../components';
import { Organization } from '../HomePage/Organization';

import { Authenticating } from './Authenticating';
import { appSaga } from './saga';
import { selectApp } from './selectors';
import { reducer, sliceKey } from './slice';

export default function Main() {
  useInjector(sliceKey, reducer, appSaga);
  useInjector(queryFilter.sliceKey, queryFilter.reducer, queryFilterSaga);
  useInjector(alerts.sliceKey, alerts.reducer, alertsSaga);

  const { userPermission } = useUserPermission();
  const { user, organization, cloudAccounts } = useSelector(selectApp);
  const [isOpenWelcomeScreen, setIsOpenWelcomeScreen] = useState(false);
  const cloudInsightDrawer = useDisclosure();

  /*if no cloud account is onboarded, show welcome screen*/
  // useEffect(() => {
  //   const show = localStorage.getItem('showWelcomeScreen');
  //   setIsOpenWelcomeScreen(
  //     !show &&
  //       !isTestEnv() &&
  //       isEmpty(cloudAccounts.data) &&
  //       !cloudAccounts.loading &&
  //       organization !== 'master',
  //   );
  // }, [cloudAccounts.data, cloudAccounts.loading, organization]);

  return (
    <>
      <KeycloakContext>
        {!user.info.id && (
          <>{!!organization ? <Authenticating /> : <Organization />}</>
        )}
        {user.info.id && (
          <UserPermissionsProvider value={{ userPermission }}>
            <SharedStateProvider>
              <Stack
                h={'full'}
                w={'full'}
                pos={'absolute'}
                spacing={0}
                overflowY="auto"
                minW="minUIWidth"
              >
                <AppNav
                  isOpenWelcomeScreen={isOpenWelcomeScreen}
                  onOpenCloudInsight={cloudInsightDrawer.onOpen}
                />
                <PageContent>
                  {/* show sentry errors in console */}
                  <Sentry.ErrorBoundary
                    onError={error => {
                      console.dir(error);
                      // alert('Backend is updated. UI needs reload');
                      // window.location.reload();
                    }}
                  >
                    <AiBrainButton />
                    {/*Open welcome screen on first login*/}
                    {/*{isOpenWelcomeScreen && (*/}
                    {/*  <WelcomeToAriksa*/}
                    {/*    isOpen={isOpenWelcomeScreen}*/}
                    {/*    onClose={() => setIsOpenWelcomeScreen(false)}*/}
                    {/*  />*/}
                    {/*)}*/}
                    {cloudInsightDrawer.isOpen && (
                      <CloudInsightDrawer
                        isOpen={cloudInsightDrawer.isOpen}
                        onClose={cloudInsightDrawer.onClose}
                      />
                    )}
                    <AppRoutes />
                  </Sentry.ErrorBoundary>
                </PageContent>
              </Stack>
            </SharedStateProvider>
          </UserPermissionsProvider>
        )}
      </KeycloakContext>
    </>
  );
}
