import React from 'react';

import { Box, Center, Flex, HStack } from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/all';
import { customTheme } from 'theme';

import { CustomerJourneyBulbIcon } from 'components/Icons';
import { CustomToastProps } from 'components/Toast/types';

export const TaskToast = (props: CustomToastProps & { onClick() }) => {
  const { description, isClosable = true, onClose, onClick } = props;
  return (
    <Box
      p={3}
      bg="white"
      borderRadius={6}
      border="1px solid"
      borderColor={customTheme.colors.componentBorder}
      h="full"
      w="md"
    >
      <HStack justify="space-between" spacing={4} h="full" align="flex-start">
        <HStack spacing={5} pr={2} py={2}>
          <HStack alignItems="center">
            <Center color="orange" boxSize={10}>
              <Center>
                <CustomerJourneyBulbIcon />
              </Center>
            </Center>
            {description && (
              <Center
                py={0}
                color={customTheme.colors.primary}
                onClick={() => onClick()}
                cursor="pointer"
                flex={1}
              >
                {description}
              </Center>
            )}
          </HStack>
        </HStack>
        <Flex h="full" justify="flex-start">
          {isClosable && (
            <Center
              boxSize={3}
              cursor="pointer"
              onClick={() => onClose()}
              as={AiOutlineClose}
            />
          )}
        </Flex>
      </HStack>
    </Box>
  );
};
