import { PageInfo } from 'app/components';

export enum PermissionFilterType {
  Access = 'Access',
  Usage = 'Usage',
}

export enum PermissionAccessType {
  Allow = 'Allow',
  Deny = 'Deny',
}

export enum PermissionUsageType {
  Unused = 'Unused',
  Used = 'Used',
}

export interface GetPolicyPermissionAccountsParams {
  account_id: string;
  entity_type: string;
  uuid: string;
  filter_on: PermissionFilterType;
  access_filter?: PermissionAccessType;
  usage_filter?: PermissionUsageType;
}

export interface GetPolicyPermissionServicesParams
  extends GetPolicyPermissionAccountsParams {
  cloud_account_id: string;
}

export interface GetPolicyPermissionResourcesParams
  extends GetPolicyPermissionServicesParams {
  service: string;
}

export interface GetPolicyPermissionActionsParams
  extends GetPolicyPermissionResourcesParams {
  resource: string;
}

export interface NetworkAssessmentParams {
  account_id: string;
  virtual_machine_access_uuid: string;
  port_from?: number;
  port_to?: number;
  source_ip?: string;
  destination_ip?: string;
  permissions?: 'ALLOW' | 'DENY';
}

export interface GetDuplicateSecurityGroupParams extends CloudAccountId {
  virtual_machine_access_uuid: string;
  port_from: string;
  port_to: string;
  ip: string;
  direction: string;
  protocol: string;
  permissions: string;
}

export interface CloudAccountId {
  account_id: string;
}

export interface CloudAccountUUID {
  account_uuid: string;
}

export interface OrgMapResourceParams {
  org_uuid: string;
  account_ids?: string;
}

export interface InsightParams extends PageInfo {
  resource_category?: string;
  resource_type: string;
  sort_by?: string;
  order_by?: string;
  resource_uuid?: string;
  identities?: boolean;
  user_type?: string;
}

export interface InventoryQueryParams extends PageInfo {}

export interface GetBusinessUnitsParams {
  cloud_provider: string;
  account_id: string;
}

export interface AssessActionsParams {
  resource_type: string;
  resource_uuid: string;
  account_ids: string;
}
export interface AssessmentParams {
  account_ids: string;

  source_type: string;
  source_uuid: string;

  target_type: string;
  target_uuid: string;

  action: string;
}

export interface CloudAccountIds {
  environment?: string;
  account_ids?: string;
  snapshot_id?: string;
}

export interface InventoryParams extends InventoryQueryParams {
  resource_type: string;
}

export interface InventoryMapQueryParams {
  account_id?: string;
  environment_id?: string;
  resource_uuid: string;
  resource_type: string;
  show?: string;
}

export interface ResourceHealthCheckProps {
  resource_type: string;
  resource_uuid?: string;
  account?: string;
}
export interface ResourceFailedChecksProps {
  resource_type: string;
  uuid: string;
  severity: string;
}

export interface ResourceDetailsProps extends ResourceHealthCheckProps {
  resource_id?: any;
  identities?: boolean;
}

export interface RemediationCommandsProps {
  resource_type: string;
  resource_uuid: string;
  account_id?: string;
  environment_id?: string;
  check_name: string;
}

export interface TagDetailsProps {
  uuid: string;
  account_ids: string;
  resource_type: string;
}

export interface UserResourceTypeParams {
  user_uuid: string;
  //environment_uuid: string;
  account_uuid: string;
  access_type: string;
  cloud_account_id: string;
}
export interface UserResourceUserTypeParams {
  account_uuid: string;
  resource_type: string;
  resource_uuid: string;
  access_type: string;
  cloud_account_id?: string;
  cloud_group_uuid?: string;
}

export interface UserMapParams extends Partial<UserResourceTypeParams> {
  resource_type: string;
}
export interface UserMapAccountParams {
  user_uuid: string;
  account_uuid: string;
  access_type: string;
}

export interface IAMUserID {
  iam_user_id: string;
  account_ids: string;
}

export interface UserPermissionsProps extends IAMUserID {
  policy_name: string;
  policy_type: string;
}

export interface RoleDetailsProps {
  account_ids: string;
  uuid: string;
}

export interface GetPolicyDocumentParams {
  account_id: string;
  policy_uuid: string;
}

export interface PolicyDetailsByIdProps {
  uuid: string;
  account_id: string;
}

export interface UserPoliciesByUserIDParams {
  account_ids: string;
  user_uuid: string;
}

export interface UserDetailsParams {
  account_ids: string;
  uuid: string;
}

export interface OrganizationDetailsParams {
  account_ids: string;
  uuid: string;
  entity: string;
}

export interface ResourceDetailsParams {
  account_ids: string;
  uuid: string;
  resource_type: string;
}

export interface SingleUserDetailsParams {
  account_ids: string;
  user_uuid: string;
}

export interface getPolicyMap {
  entity_type: string;
  entity_uuid: string;
  account_id: string;
}

export interface getPolicyMapResourceType {
  entity_type: string;
  permission_type: string;
  entity_uuid: string;
  cloud_account_id: string;
  access_level: string;
  account_id: string;
  resource_type?: string;
}

export interface getTagsCharts {
  tag_chart_type: string;
  account_ids: string;
  resource_types: string;
}

export interface OrgResourcesParams {
  account_uuid: string;
  org_uuid: string;
}

export interface getCountDiff {
  account_ids: string;
  existing_snapshot_id?: string;
  new_snapshot_id?: string;
}

export interface getCountByRegion {
  account_ids: string;
  snapshot_id?: string;
}

export interface getInventorySummaryTable {
  resource_type: string;
  account_ids: string;
  page_number?: string;
  page_size?: string;
  snapshot_id?: string;
}

export interface getRolesPolicies {
  role_uuid: string;
  account_id: string;
}

export interface getOrgMap {
  account_uuid: string;
  resource_type: string;
  resource_uuid: string;
  show?: string | string[];
}

export interface getTrustedEntities {
  entity_type: string;
  account_id: string;
  entity_uuid: string;
}

export interface getInstanceListProps {
  account_ids?: string;
  resource_type: string;
  resource_uuid?: string;
  page_number?: number;
  page_size?: number;
  sort_by?: string;
  order_by?: string;
}

export interface getInstanceListByIdProps {
  uuid: string;
  account_ids: string;
  resource_type: string;
}

export interface getCloudContextSummaryProps {
  account_ids: string;
  snapshot_id?: string;
  managementAccountUUID?: string;
}

export interface UserRolesByUserIDParams extends UserPoliciesByUserIDParams {}
