import React, { FC, useEffect } from 'react';

import {
  CloudAccountGetResponse,
  CloudProviders,
} from '@ariksa/cloud-account/api';
import { Box, Center, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { Api } from 'api/index';
import { CloudAccountService } from 'api/services';
import {
  DetailsIdentifier,
  getIcon,
  PageHeaderWithIcon,
} from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { PrimaryButton } from 'components/DataEntry';
import {
  IconTypes,
  LearnMoreIcon,
  QuestionCircleIcon,
  RemediateIcon,
} from 'components/Icons';
import { Modal } from 'components/Overlay';
import { errorToast } from 'components/Toast';
import { useEnvironmentOptions } from 'containers/App/hooks/useEnvironmentOptions';
import { DashboardOverviewTooltip } from 'containers/Dashboard/utils/OverviewDashboardTooltip';
import { useCloudAccountsFunctions } from 'containers/Setup/CloudAccounts/Components/hooks/useCloudAccountsFunctions';
import { selectCloudAccounts } from 'containers/Setup/CloudAccounts/selectors';
import { actions } from 'containers/Setup/CloudAccounts/slice';

interface Props {
  onClose();
  isOpen: boolean;
  account: CloudAccountGetResponse;
}

export const EnableRemediation: FC<Props> = props => {
  const { isOpen, onClose, account } = props;

  const { config } = useSelector(selectCloudAccounts);
  const { accountEnvironmentMapping } = useEnvironmentOptions();
  const dispatch = useDispatch();
  const { getCloudAccounts } = useCloudAccountsFunctions();

  useEffect(() => {
    dispatch(
      actions.getRemediationConfig({
        q: {
          cloudType: account.cloud_type!,
        },
      }),
    );
  }, [dispatch, account]);

  const renderInfoBox = (value, type) => {
    return (
      <Box
        px={4}
        py={1}
        border="1px solid"
        borderRadius={6}
        borderColor="gray.100"
      >
        <HStack align="center" spacing={3}>
          <Center boxSize={5} color="primary">
            <Center pt={type === CloudProviders.Aws ? 1 : 0}>
              {getIcon(type)}
            </Center>
          </Center>
          <Box>{value}</Box>
        </HStack>
      </Box>
    );
  };

  const handleSummit = () => {
    const { cloud_type, uuid, cloud_account_id } = account;
    if (!config.data) {
      errorToast({
        title: 'Failed to get onboarding config',
        description: 'Please try again later',
      });
      return;
    }

    CloudAccountService.CloudAccount.onboardRemediationAccount({
      cloudType: cloud_type!,
      uuid: uuid,
    })
      .then(r => {
        console.log(r);
        getCloudAccounts();
      })
      .catch(e => {
        console.error(e);
      });

    const { aws_config } = config.data;

    const params: any = {
      stackName: `Ariksa-JIT-${cloud_account_id}-${uuidv4()}`,
      param_ExternalID: aws_config?.external_id,
      templateURL: aws_config?.cfs_template_url,
      param_AriksaAdminAccountId: aws_config?.account_id,
    };

    const redirectUrl = Api.awsOnboardRedirectURL(params);
    onClose();
    window.open(redirectUrl, '_blank');
  };

  return (
    <Box>
      <Modal
        styles={{ modal: { size: '2xl' } }}
        isOpen={isOpen}
        onClose={onClose}
        header={
          <HStack justify="space-between" pr={6}>
            <PageHeaderWithIcon
              label="Enable Remediation"
              icon={<RemediateIcon />}
            />
            <DashboardOverviewTooltip
              button={
                <HStack
                  border="1px solid"
                  borderColor="gray.100"
                  borderRadius={6}
                  px={3}
                  py={1}
                >
                  <Box boxSize={6} color="primary">
                    <Center>
                      <LearnMoreIcon />
                    </Center>
                  </Box>
                  <Box>Learn More</Box>
                </HStack>
              }
              header="Remediate with no manual effort..."
              icon={<RemediateIcon />}
              footerText="Ok"
              tooltip={
                <Stack>
                  <Box>
                    In addition to detection and investigation, Ariksa allows
                    you to enable single-click remediation to eliminate manual
                    work.
                  </Box>
                  <Box>
                    For common scenarios, Ariksa also enables automated
                    remediation to significantly lower MTTR
                  </Box>
                </Stack>
              }
            />
          </HStack>
        }
        body={
          <Stack spacing={6}>
            <Stack spacing={6}>
              <DetailsIdentifier
                label={'Account'}
                value={renderInfoBox(
                  account?.name +
                    ' (Account ID: ' +
                    account?.cloud_account_id +
                    ')',
                  account?.cloud_type,
                )}
                isInline={false}
              />
              <DetailsIdentifier
                label={'Environment'}
                value={renderInfoBox(
                  accountEnvironmentMapping?.[account?.uuid]?.name,
                  IconTypes.Environment,
                )}
                isInline={false}
              />
              <Flex display="inline" pt={3}>
                <Text color="primary" display="inline">
                  NOTE:{' '}
                </Text>
                <Text display="inline">
                  Remediation requires Ariksa to have permissions to make
                  changes to resource configurations. Ariksa will create a
                  cross- account role or service account with permissions
                  required for remediation{' '}
                </Text>
                <Box
                  color="primary"
                  cursor="pointer"
                  display="inline-block"
                  boxSize={4}
                  verticalAlign="text-top"
                >
                  <CustomTooltip
                    label={'Click to see permissions'}
                    styles={{ text: { display: 'inline' } }}
                    w={24}
                  >
                    <QuestionCircleIcon />
                  </CustomTooltip>
                </Box>
              </Flex>
            </Stack>
            <HStack justify={'space-between'} pt={10}>
              <PrimaryButton onClick={onClose}>Cancel</PrimaryButton>
              <PrimaryButton
                onClick={handleSummit}
                isLoading={config.isLoading}
              >
                + Enable Remediation
              </PrimaryButton>
            </HStack>
          </Stack>
        }
      />
    </Box>
  );
};
