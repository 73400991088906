import React, { FC, useState } from 'react';

import { CloudAccountGetResponse } from '@ariksa/cloud-account/api';
import { Stack, HStack, Box, Textarea, Switch } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import { defaultStyles as defaultFormStyles } from 'components/DataEntry/Form/styles';
import {
  AriksaIcon,
  CancelButton,
  DetailsIdentifier,
  DisableIcon,
  Modal,
  PrimaryButton,
  RefreshIcon,
  renderTime,
  Select,
} from 'components/index';
import {
  getPausedUntilTime,
  getRestartTime,
  pauseDiscoveryTimeIntervalOptions,
} from 'containers/Setup/CloudAccounts/Components/AccountRediscovery/utils';
import { useCloudAccountsFunctions } from 'containers/Setup/CloudAccounts/Components/hooks/useCloudAccountsFunctions';
import { selectCloudAccounts } from 'containers/Setup/CloudAccounts/selectors';
import { actions } from 'containers/Setup/CloudAccounts/slice';

interface Props {
  isOpen: boolean;
  onClose: (isSubmit?: boolean) => void;
  row: CloudAccountGetResponse;
  managementAccountId?: string;
}

export const PauseDiscovery: FC<Props> = props => {
  const { isOpen, onClose, row, managementAccountId } = props;
  const { accountRediscovery } = useSelector(selectCloudAccounts);
  const dispatch = useDispatch();
  const [pauseDiscoveryTime, setPauseDiscoveryTime] = useState<
    Record<string, any>
  >(pauseDiscoveryTimeIntervalOptions[0]);
  const [comment, setComment] = useState('');
  const [pauseDiscoveryForAll, setPauseDiscoveryForAll] = useState(true);
  const { getCloudAccounts, getMemberAccounts } = useCloudAccountsFunctions();

  const handleOnSuccess = () => {
    !!managementAccountId
      ? getMemberAccounts(managementAccountId)
      : getCloudAccounts();
    onClose();
  };

  const onConfirm = () => {
    if (row?.is_paused)
      dispatch(
        actions.resumeDiscovery({
          q: { uuid: row?.uuid },
          onSuccess: handleOnSuccess,
        }),
      );
    else {
      const time = getPausedUntilTime(pauseDiscoveryTime?.value);
      dispatch(
        actions.pauseDiscovery({
          q: {
            uuid: row?.uuid,
            managementOnly: !pauseDiscoveryForAll,
            cloudAccountPauseRequest: {
              comment,
              paused_till:
                time === 'None'
                  ? undefined
                  : dayjs(time).utc().local().format(),
            },
          },
          onSuccess: handleOnSuccess,
        }),
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <HStack>
          <AriksaIcon
            icon={row?.is_paused ? <RefreshIcon /> : <DisableIcon />}
            bg={row?.is_paused ? 'green' : 'red'}
            size="sm"
          />
          <Box>{row?.is_paused ? 'Restart' : 'Pause'} Account Discovery</Box>
        </HStack>
      }
      size="xl"
      body={
        <Stack spacing={4}>
          <DetailsIdentifier label="Account" value={row?.cloud_account_id} />
          <DetailsIdentifier label="Type" value={row?.aws?.account_type} />
          {row?.aws?.account_type === 'Management' && (
            <DetailsIdentifier
              label="Pause discovery for all member accounts as well"
              value={
                <Switch
                  isChecked={pauseDiscoveryForAll}
                  onChange={e => setPauseDiscoveryForAll(e.target.checked)}
                />
              }
              isInline={false}
            />
          )}
          {!row?.is_paused && (
            <DetailsIdentifier
              label="How long do you want to pause discovery?"
              value={
                <HStack spacing={4}>
                  <Box w={48} h="full">
                    <Select
                      options={pauseDiscoveryTimeIntervalOptions}
                      value={pauseDiscoveryTime}
                      onChange={setPauseDiscoveryTime}
                      menuPlacement="auto"
                      maxMenuHeight={100}
                      //menuPortalTarget={menuPortalTarget}
                    />
                  </Box>
                  <DetailsIdentifier
                    label="Restart at"
                    value={getRestartTime(pauseDiscoveryTime?.value)}
                  ></DetailsIdentifier>
                </HStack>
              }
              spacing={3}
              isInline={false}
            />
          )}
          {row?.is_paused && (
            <DetailsIdentifier
              label={'Discovery is already scheduled to restart at'}
              value={renderTime({ value: row?.paused_till })}
            />
          )}
          {row?.is_paused && (
            <Box color="primary" pt={5}>
              NOTE: Account discovery will be initiated at scheduled time after
              confirmation
            </Box>
          )}
          {!row?.is_paused && (
            <DetailsIdentifier
              label="Comments"
              value={
                <Textarea
                  {...defaultFormStyles.textAreaField?.textArea}
                  value={comment}
                  onChange={e => setComment(e.target.value)}
                />
              }
              spacing={3}
              isInline={false}
              pt={4}
            />
          )}
          <HStack justify="space-between" pt={10}>
            <CancelButton onClick={() => onClose()} />
            <PrimaryButton
              onClick={onConfirm}
              isLoading={accountRediscovery.isLoading}
            >
              Confirm
            </PrimaryButton>
          </HStack>
        </Stack>
      }
    />
  );
};
