import React, { FC } from 'react';

import { Box, Stack } from '@chakra-ui/react';

import { DetailsIdentifier } from 'components/DataDisplay';
import { Modal } from 'components/Overlay';
import { IModalProps } from 'components/Overlay/Modal/types';
import { limitedString } from 'utils/string';

export interface ResourceInfo {
  resourceType?: string;
  resourceName?: string;
  resourceId?: string;
}

export interface IResourceModal extends IModalProps {
  resourceInfo?: ResourceInfo;
  bodyHeight?: number;
}

export const ResourceModal: FC<IResourceModal> = props => {
  const {
    isOpen,
    onClose,
    resourceInfo = {},
    body,
    bodyHeight = 400,
    ...rest
  } = props;
  const { resourceType, resourceName, resourceId } = resourceInfo;

  const showResourceDetails = resourceId || resourceType || resourceName;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      {...rest}
      body={
        <Box h={bodyHeight + 'px'} overflow={'auto'}>
          <Stack spacing={4} h="full" w={'full'}>
            {showResourceDetails && (
              <Stack spacing={1}>
                {/*show resourceType if available*/}
                {resourceType && (
                  <DetailsIdentifier
                    label="Identity Type"
                    value={resourceType}
                  />
                )}
                {/*show resourceName if available*/}
                {resourceName && (
                  <DetailsIdentifier
                    label="Identity Name"
                    value={resourceName}
                  />
                )}
                {/*show resourceId if available*/}
                {resourceId && (
                  <DetailsIdentifier
                    label="Cloud ID"
                    value={limitedString(resourceId, 100)}
                  />
                )}
              </Stack>
            )}
            <Stack flex={1}>{body}</Stack>
          </Stack>
        </Box>
      }
    />
  );
};
