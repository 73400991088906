import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types/index';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.alerts || initialState;

export const selectAlerts = createSelector(
  [selectDomain],
  alertsState => alertsState,
);

export const selectAlertFilters = createSelector(
  [selectDomain],
  alertsState => alertsState.filters,
);

// loading alerts if any of the severity types is loading
export const selectIsLoadingAlerts = createSelector([selectDomain], alerts => {
  return (
    alerts.assetsAlerts.loading ||
    alerts.criticalAlerts.loading ||
    alerts.mediumAlerts.loading ||
    alerts.lowAlerts.loading
  );
});

export const selectEscalate = createSelector(
  [selectDomain],
  alertsState => alertsState.escalate,
);

export const selectSuppress = createSelector(
  [selectDomain],
  alertsState => alertsState.suppress,
);

export const selectForward = createSelector(
  [selectDomain],
  alertsState => alertsState.forward,
);
