import React, { FC, BaseSyntheticEvent, useMemo, ReactNode } from 'react';

import { Box, Stack, Button, ButtonGroup, Flex } from '@chakra-ui/react';
import cloneDeep from 'lodash/cloneDeep';
import keys from 'lodash/keys';
import map from 'lodash/map';
import merge from 'lodash/merge';
import { useForm, FormContext, UseFormOptions } from 'react-hook-form';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import { PrimaryButton } from 'components/DataEntry';
import { Drawer } from 'components/Overlay';
import { DrawerStyles } from 'components/Overlay/Drawer/types';

import { useFormComponent } from '../hooks/useFormComponent';
import { StyleCtx } from '../hooks/useStyles';
import { defaultStyles } from '../styles';
import { FormStyles, ButtonOptionProps, FormSchema } from '../types';

const defaultButtonOptions: ButtonOptionProps = {
  submit: { isVisible: true, name: 'Submit' },
  reset: { isVisible: false, name: 'Reset', onClick: () => null },
  back: { isVisible: false, name: 'Back', onClick: () => null },
};

export interface FormProps {
  isLoading?: boolean;
  title?: ReactNode;
  titleIcon?: ReactNode;
  isTitleIconFilled?: boolean;
  schema?: FormSchema;
  validationSchema?: any;
  overwriteDefaultStyles?: boolean;
  formOptions?: UseFormOptions;
  buttonOptions?: ButtonOptionProps;
  styles?: FormStyles;
  handleSubmit: (values: any, e?: BaseSyntheticEvent) => void;
  handleBackSubmit?: (values: any, e?: BaseSyntheticEvent) => void;
  showHelp?: boolean;
  helpDrawer?: {
    isOpen: boolean;
    onClose();
    content: ReactNode;
    header?: ReactNode;
    styles?: DrawerStyles;
  };
  formValidation?: Object;
}

export const Form: FC<FormProps> = ({
  isLoading,
  title,
  titleIcon,
  isTitleIconFilled = true,
  schema = {},
  formOptions,
  handleSubmit,
  handleBackSubmit,
  buttonOptions,
  overwriteDefaultStyles,
  styles = {},
  showHelp,
  children,
  formValidation,
  helpDrawer,
}) => {
  let form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    validationSchema: formValidation,
    submitFocusError: true,
    validateCriteriaMode: 'all',
    ...formOptions,
  });

  const { renderField } = useFormComponent();

  const value = form.watch({ nest: true });
  const submitted = { ...form.formState };

  const baseStyles = useMemo(() => {
    const props = cloneDeep(defaultStyles);
    return overwriteDefaultStyles ? styles : merge(props, styles);
  }, [styles, overwriteDefaultStyles]);

  const buttonProps = useMemo(() => {
    const props = cloneDeep(defaultButtonOptions);
    return merge(props, buttonOptions);
  }, [buttonOptions]);

  const { reset, back, submit, customButtonOptions } = buttonProps;

  return (
    <Stack w="full" isInline h="full" spacing={4} justify="space-between">
      <Box w={baseStyles.form?.formWidth}>
        <StyleCtx.Provider value={baseStyles}>
          <FormContext {...form}>
            <Box w="full" as="form" onSubmit={form.handleSubmit(handleSubmit)}>
              <Box {...baseStyles.form?.container}>
                {!!title && (
                  <Box w="full" pb={6}>
                    <PageHeaderWithIcon
                      label={title}
                      reversed
                      icon={titleIcon}
                      iconFilled={isTitleIconFilled}
                      {...baseStyles.form?.title}
                    />
                  </Box>
                )}
                <Box {...baseStyles.form?.inputWrapper}>
                  <Stack spacing={baseStyles.form?.fieldSpacing}>
                    {children && children}
                    {map(Object.entries(schema), renderField(isLoading))}
                  </Stack>
                </Box>
                <Box>
                  <ButtonGroup {...baseStyles.form?.buttonGroup}>
                    {reset && reset?.isVisible && (
                      <Button
                        type="reset"
                        onClick={reset?.onClick}
                        isDisabled={reset?.isDisabled}
                        isLoading={reset?.isLoading}
                        {...baseStyles.form?.resetButton}
                      >
                        {reset.name}
                      </Button>
                    )}
                    <ButtonGroup {...baseStyles.form?.submitButtonGroup}>
                      {back && back?.isVisible && (
                        <Button
                          type="button"
                          {...baseStyles.form?.backButton}
                          onClick={
                            handleBackSubmit !== undefined
                              ? () => handleBackSubmit(value)
                              : back.onClick
                          }
                          isDisabled={back.isDisabled}
                          isLoading={back.isLoading}
                        >
                          {back.name}
                        </Button>
                      )}
                      {customButtonOptions &&
                        map(
                          customButtonOptions,
                          o =>
                            o.isVisible && (
                              <Button size="sm" {...o}>
                                {o.name}
                              </Button>
                            ),
                        )}

                      {submit && submit?.isVisible && (
                        <PrimaryButton
                          type="submit"
                          isLoading={
                            isLoading ||
                            submit?.isLoading ||
                            submitted.isSubmitting
                          }
                          isDisabled={
                            isLoading ||
                            submit?.isDisabled ||
                            submitted.isSubmitting ||
                            !!keys(form.errors).length
                          }
                          {...baseStyles.form?.submitButton}
                        >
                          {submit?.name}
                        </PrimaryButton>
                      )}
                    </ButtonGroup>
                  </ButtonGroup>
                </Box>
              </Box>
            </Box>
          </FormContext>
        </StyleCtx.Provider>
      </Box>

      {helpDrawer?.isOpen && (
        <Drawer
          isOpen={helpDrawer?.isOpen}
          onClose={helpDrawer?.onClose}
          closeButton
          body={helpDrawer?.content}
          header={helpDrawer?.header}
          styles={{ drawer: { size: 'xl' }, ...helpDrawer?.styles }}
        />
      )}

      {showHelp && (
        <Flex {...baseStyles.form?.helpWrapper}>
          {map(
            schema,
            each =>
              each?.isHelpOpen && (
                <Box {...baseStyles.form?.helpComponent}>
                  <Box>{each?.helpComponent}</Box>
                </Box>
              ),
          )}
        </Flex>
      )}
    </Stack>
  );
};
