/**
 *
 * Common Buttons
 *
 */
import React, { FC, ReactNode } from 'react';

import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  Tooltip,
  Center,
} from '@chakra-ui/react';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { MdAdd } from 'react-icons/md';

import {
  CopyIcon,
  customColorButtonStyles,
  tooltipProps,
  TrashIcon,
} from 'app/components';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { DownloadIcon } from 'components/Icons';

import { EditIcon, FilterIcon, LeftArrowIcon, RefreshIcon } from '../../Icons';

import {
  deleteButtonStyles,
  editButtonStyles,
  grayIconButtonStyles,
  primaryButtonStyles,
  primaryIconButtonStyles,
  cancelButtonStyles,
  passwordFieldIconStyles,
} from './styles';

//import show = Mocha.reporters.Base.cursor.show;

interface Props extends ButtonProps {
  label?: string | ReactNode;
}

interface CProps extends Props {
  showIcon?: boolean;
  buttonType: 'save' | 'add' | 'delete' | 'edit' | 'cancel' | 'otherColor';
  customColor?: string; //in case otherColor button type is provided
}

export const DownloadButton: React.FC<Props> = props => {
  return (
    <Button
      {...primaryButtonStyles}
      /*color={'green'}
      borderColor={'green'}
      _hover={{ bg: 'green', color: '#fff', borderColor: '#fff' }}
      _focus={{ bg: 'green', color: '#fff', borderColor: '#fff' }}
      _active={{ bg: 'green', color: '#fff', borderColor: '#fff' }}*/
      rightIcon={<DownloadIcon />}
      {...props}
    >
      Download
    </Button>
  );
};

export const SaveButton: React.FC<Props> = props => {
  return <CustomButton buttonType="save" {...props} showIcon={false} />;
};

export const AddButton: React.FC<Props> = props => {
  return <CustomButton buttonType="add" {...props} />;
};

export const DeleteButton: React.FC<Props> = props => {
  return <CustomButton buttonType="delete" {...props} />;
};

export const CancelButton: React.FC<Props> = props => {
  return <CustomButton buttonType="cancel" showIcon={false} {...props} />;
};

export const EditButton: React.FC<Props> = props => {
  return <CustomButton buttonType="edit" {...props} />;
};

export const BackIconButton: React.FC<ButtonProps> = props => {
  const { ...rest } = props;
  return (
    <Button {...primaryIconButtonStyles} {...rest}>
      <LeftArrowIcon />
    </Button>
  );
};

export const RefreshIconButton: React.FC<ButtonProps> = props => {
  const { ...rest } = props;
  return (
    <Button {...grayIconButtonStyles} {...rest}>
      <RefreshIcon size={18} />
    </Button>
  );
};

export interface PrimaryButtonProps extends ButtonProps {}

export const PrimaryButton: FC<
  ButtonProps & { tooltip?: string | ReactNode; iconSize?: number }
> = props => {
  const { tooltip, leftIcon, iconSize = 4, ...rest } = props;
  return (
    <Tooltip {...tooltipProps} label={tooltip}>
      <Button
        {...primaryButtonStyles}
        leftIcon={
          leftIcon && (
            <Center w={iconSize} h={iconSize}>
              {leftIcon}
            </Center>
          )
        }
        {...rest}
      >
        {props.children}
      </Button>
    </Tooltip>
  );
};

export const FilterButton: FC<ButtonProps> = props => (
  <PrimaryButton leftIcon={<FilterIcon />} {...props}>
    Filters
  </PrimaryButton>
);

export const PrimaryIconButton: FC<
  IconButtonProps & { tooltip?: string; tooltipW?: string | number }
> = props => {
  const { tooltip, tooltipW = 24, ...rest } = props;
  return (
    <CustomTooltip w={tooltipW} textAlign="center" label={tooltip}>
      <IconButton
        {...primaryButtonStyles}
        p={1.5}
        borderRadius="xl"
        {...rest}
      />
    </CustomTooltip>
  );
};

export const DownloadIconButton: FC<
  IconButtonProps & { tooltip?: string }
> = props => {
  return (
    <PrimaryIconButton tooltip="Download" {...props}>
      {!props.isLoading && <DownloadIcon />}
    </PrimaryIconButton>
  );
};

export const PasswordFieldIconButton: FC<
  {
    show: boolean;
    toggleShowPassword: () => void;
  } & ButtonProps
> = props => {
  const { show, toggleShowPassword, ...rest } = props;
  return (
    <Button
      {...passwordFieldIconStyles}
      onClick={() => toggleShowPassword()}
      {...rest}
    >
      {show ? <BsEye /> : <BsEyeSlash />}
    </Button>
  );
};

export const CopyToClipboardIconButton: FC<
  {
    show?: boolean;
    value?: string;
    onClick?: () => void;
  } & ButtonProps
> = props => {
  const { show = true, value = '', onClick, ...rest } = props;
  const handleClick = () => {
    if (onClick) {
      onClick?.();
    } else {
      navigator.clipboard.writeText((value ?? '').toString());
    }
  };

  return (
    <CustomTooltip label={'Copy to clipboard'}>
      <Button
        {...passwordFieldIconStyles}
        onClick={handleClick}
        {...rest}
        _active={{ transform: 'scale(0.90)' }}
      >
        <CopyIcon />
      </Button>
    </CustomTooltip>
  );
};

export const CustomButton: FC<CProps> = props => {
  const {
    label,
    buttonType,
    customColor,
    showIcon = true,
    leftIcon,
    ...rest
  } = props;
  const styles = {
    save: primaryButtonStyles,
    add: primaryButtonStyles,
    delete: deleteButtonStyles,
    edit: editButtonStyles,
    cancel: cancelButtonStyles,
    otherColor: customColorButtonStyles(customColor),
  };
  const defaultLabel = {
    save: 'Save',
    add: 'Add',
    delete: 'Delete',
    edit: 'Edit',
    cancel: 'Cancel',
  };
  const icon = {
    add: <MdAdd />,
    delete: <TrashIcon />,
    edit: <EditIcon />,
  };
  return (
    <Button
      {...styles[buttonType]}
      leftIcon={
        showIcon || leftIcon ? (
          <Center w={4} h={4}>
            {leftIcon ?? icon[buttonType]}
          </Center>
        ) : undefined
      }
      {...rest}
    >
      {label || defaultLabel[buttonType]}
    </Button>
  );
};
