import React, { FC, ReactNode } from 'react';

import { Box, BoxProps, Stack, StackProps } from '@chakra-ui/react';
import { isBoolean, isNumber } from 'lodash';

export interface DetailsFieldProps extends StackProps {
  label: string | ReactNode;
  value?: string | boolean | number | ReactNode;
  placeholder?: string;
  flex?: string | number;
  width?: string | number;
  styles?: { label?: BoxProps; value?: BoxProps };
  darkMode?: boolean;
  isLoading?: boolean;
}

export const DetailsField: FC<DetailsFieldProps> = props => {
  const {
    label,
    value,
    flex = '',
    placeholder = '',
    styles = {},
    darkMode,
    isInline = true,
    width,
    spacing = 1,
    ...rest
  } = props;
  const formatBoolean = value => (value ? 'Yes' : 'No');

  return (
    <Box flex={flex} w={width}>
      <Stack spacing={spacing} align="start" isInline={isInline} {...rest}>
        <Box
          //color={darkMode ? 'gray.100' : 'gray.200'}
          fontWeight={600}
          {...styles.label}
        >
          {label}:
        </Box>

        <Box
          color={darkMode ? 'gray.200' : 'gray.300'}
          {...styles.value}
          flex={1}
          w="full"
        >
          {isBoolean(value)
            ? formatBoolean(value)
            : isNumber(value)
            ? `${value}`
            : value
            ? value
            : placeholder || '-'}
        </Box>
      </Stack>
    </Box>
  );
};
