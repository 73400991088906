import React, { FC } from 'react';

import { Box, Center, Input, LightMode, Stack } from '@chakra-ui/react';
import { isString } from 'lodash';

import { Heading1, Heading3, Heading4 } from 'components/DataDisplay';
import { textFieldStyles } from 'components/DataEntry';
import { Logo } from 'components/Navigation/NavBar/Logo';

interface IOrganizationLoginInput {
  handleSubmit(data);
}

export const OrganizationLoginInput: FC<IOrganizationLoginInput> = props => {
  const { handleSubmit } = props;

  return (
    <LightMode>
      <Box
        bg="gray.50"
        px={[6, 8, 12]}
        py={'120px'}
        minH={window.innerHeight}
        id="login"
      >
        <Stack spacing={40}>
          <Box>
            <Stack spacing={0} mt={10}>
              <Center>
                <Logo w={48} />
              </Center>
              <Center color={'primary'}>
                <Heading1> AI-Powered Full-stack Cloud Security</Heading1>
              </Center>
            </Stack>
          </Box>
          <Center>
            <Stack maxW="md" w={'400px'} spacing={4}>
              <Heading3>Name of your Organization</Heading3>
              <Input
                {...textFieldStyles.input}
                borderRadius={6}
                h={'38px'}
                placeholder={'Type and press Enter'}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    if (!isString(e.target.value)) {
                      return;
                    }

                    handleSubmit({ organization: e.target.value?.trim() });
                  }
                }}
              />
              {/*<Form*/}
              {/*  schema={{*/}
              {/*    organization: {*/}
              {/*      type: 'text',*/}
              {/*      placeholder: 'Organization',*/}
              {/*      isRequired: true,*/}
              {/*    },*/}
              {/*  }}*/}
              {/*  buttonOptions={{*/}
              {/*    submit: { name: 'Go to Login' },*/}
              {/*  }}*/}
              {/*  styles={{*/}
              {/*    form: {*/}
              {/*      formWidth: 'full',*/}
              {/*      container: { mt: 1 },*/}
              {/*      buttonGroup: { mt: 8, w: 'full' },*/}
              {/*      submitButton: {*/}
              {/*        color: 'white',*/}
              {/*        background: 'primary',*/}
              {/*        w: 'full',*/}
              {/*        size: 'lg',*/}
              {/*        _hover: {*/}
              {/*          color: 'white',*/}
              {/*          background: 'primary',*/}
              {/*          borderShadow: '10px red',*/}
              {/*        },*/}
              {/*      },*/}
              {/*      submitButtonGroup: {*/}
              {/*        w: 'full',*/}
              {/*      },*/}
              {/*    },*/}
              {/*    textField: {*/}
              {/*      input: { fontSize: 'md' },*/}
              {/*    },*/}
              {/*    objectField: {*/}
              {/*      ...inlineObjectFieldStyles,*/}
              {/*      propertyContainer: { w: 'full' },*/}
              {/*    },*/}
              {/*  }}*/}
              {/*  overwriteDefaultStyles*/}
              {/*  handleSubmit={handleSubmit}*/}
              {/*/>*/}
            </Stack>
          </Center>
        </Stack>
      </Box>
    </LightMode>
  );
};
