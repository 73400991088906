import { barStyles, commonStyles } from '../styles';
import { customizedLegend } from '../utils';

export const defaultStyles = {
  barChart: {
    margin: {
      top: 30,
      right: 30,
    },
  },
  tooltip: {
    contentStyle: {
      fontSize: '12px',
    },
  },
  legend: {
    wrapperStyle: {
      top: 0,
    },
    align: 'right',
    iconSize: 8,
    formatter: customizedLegend,
  },
  bar: barStyles,
  ...commonStyles,
};
