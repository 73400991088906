/**
 *
 * Drawer
 *
 */
import React, { memo, useMemo, useRef } from 'react';

import {
  Drawer as CDrawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
} from '@chakra-ui/react';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import { usePortalRef } from 'components/Overlay';

import { modalCloseButtonIconStyles } from '../../DataEntry/Button/styles';

import { defaultStyles } from './styles';
import { IDrawerProps } from './types';

export const Drawer = memo((props: IDrawerProps) => {
  const {
    buttonLabel,
    styles,
    body,
    header,
    onOpen,
    isOpen,
    footer,
    onClose,
    closeButton,
    isDisabled,
    overwriteDefaultStyles,
  } = props;
  const handleOnClick = () => {
    if (buttonLabel && onOpen) isOpen ? onClose() : onOpen();
  };

  const baseStyles = useMemo(() => {
    const props = cloneDeep(defaultStyles);
    return overwriteDefaultStyles ? styles : merge(props, styles);
  }, [styles, overwriteDefaultStyles]);

  return (
    <>
      {buttonLabel && (
        <Button
          onClick={handleOnClick}
          {...baseStyles?.button}
          isDisabled={isDisabled}
        >
          {buttonLabel}
        </Button>
      )}
      <CDrawer
        isOpen={isOpen}
        onClose={onClose}
        {...baseStyles?.drawer}
        /*portalProps={{
          containerRef: ref as any,
        }}*/
      >
        <DrawerOverlay>
          <DrawerContent {...baseStyles?.content}>
            {closeButton && (
              <DrawerCloseButton
                {...modalCloseButtonIconStyles}
                {...baseStyles?.closeButton}
              />
            )}
            {header && (
              <DrawerHeader {...baseStyles?.header}>{header}</DrawerHeader>
            )}

            <DrawerBody {...baseStyles?.body}>{body}</DrawerBody>

            {footer && (
              <DrawerFooter {...baseStyles?.footer}>{footer}</DrawerFooter>
            )}
          </DrawerContent>
        </DrawerOverlay>
      </CDrawer>
    </>
  );
});
