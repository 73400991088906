import React, { useCallback, useEffect, useState } from 'react';

import {
  CloudAccountGetResponse,
  CloudProviders,
} from '@ariksa/cloud-account/api';
import {
  Box,
  Center,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { forEach, identity, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import {
  getIcon,
  getProviderName,
  IconTypes,
  renderTableHeaderWithLoader,
  renderTime,
  StackedCell,
  Tag,
  timeLabel,
  timeTooltipLabel,
  WithResourceIcon,
} from 'app/components';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { selectApp } from 'containers/App/selectors';
import { selectCloudAccounts } from 'containers/Setup/CloudAccounts/selectors';
import { selectSharedState } from 'containers/SharedState/selectors';

import { CloudAccountCard } from './Components/CloudAccountCard';

export const renderRow = ({ row }) => {
  return <CloudAccountCard row={row} />;
};

export const renderStatusTag = (value: any, bgColor?: string) => {
  if (!value) return '-';
  const styles = color => ({
    bg: !!bgColor ? bgColor : color,
    py: 0.5,
    color: `white`,
  });
  let color = 'critical';
  if (value === 'SUCCESS' || value === 'COMPLETE' || value === 'SCHEDULED')
    color = 'green.300';
  else if (value === 'PENDING') color = 'blue.300';
  else if (value === 'UNAUTHORIZED') color = 'orange.300';
  return (
    <Tag
      label={value}
      styles={{ tag: { ...styles(color) }, label: { color: 'inherit' } }}
    />
  );
};

export const renderStatus = ({ value, row }) => {
  return (
    <>
      {value === 'UNAUTHORIZED' || value === 'PENDING' ? (
        <CustomTooltip>{renderStatusTag(value)}</CustomTooltip>
      ) : (
        renderStatusTag(value)
      )}
    </>
  );
};

export const onBoardRender = ({ value }) => {
  if (value.success === undefined) {
    return '-';
  }
  return (
    <StackedCell
      upper={value.success + ' out of'}
      lower={value.total + ' account' + (value.total > 1 ? 's' : '')}
    />
  );
};

export const useCloudAccountTableColumns = () => {
  const { vulnerabilityScanner, dataScanner } = useSelector(
    selectCloudAccounts,
  );
  const { snapshotStatus } = useSelector(selectSharedState);
  const showDeployedOutposts = useDisclosure();
  const [currentRow, setCurrentRow] = useState<Record<string, any>>({});

  const name = {
    header: <Box pl={10}>Name</Box>,
    accessor: 'name',
    align: 'left',
    render: ({ row }) => (
      <WithResourceIcon
        resourceType={row.cloud_type}
        iconFilled={false}
        iconSize="regular"
      >
        <StackedCell upper={row?.name} lower={row.cloud_account_id} />
      </WithResourceIcon>
    ),
  };

  const type = {
    header: 'Type',
    accessor: 'aws.account_type',
    align: 'left',
    render: ({ row }) => {
      return `${row[row.cloud_type]?.account_type ?? '-'}`;
    },
  };

  const added = {
    header: 'Added',
    render: ({ row }) => (
      <StackedCell
        upper={row.created_by}
        lower={timeLabel(row?.created_date)}
        lowerTooltip={timeTooltipLabel(row?.created_date)}
      />
    ),
    align: 'left',
    styles: {
      minW: '200px',
    },
  };

  const onboarded: TableColumnProps = {
    header: 'Onboarded',
    accessor: 'count',
    render: onBoardRender,
    align: 'left',
  };

  const snapshotStatusColumn = {
    header: renderTableHeaderWithLoader(
      'Sync Status',
      snapshotStatus.isLoading,
    ),
    align: 'left',
    render: ({ row }) => {
      return row?.status?.code !== 'SUCCESS' ? (
        renderStatusTag(row?.status?.code)
      ) : (
        <StackedCell
          upper={
            snapshotStatus.isLoading
              ? '-'
              : renderStatusTag(snapshotStatus.data?.[row.uuid]?.status ?? '')
          }
          lower={
            snapshotStatus.isLoading
              ? '-'
              : renderTime({
                  value: snapshotStatus.data?.[row.uuid]?.created_at,
                })
          }
          showUpperTooltip={false}
          showLowerTooltip={false}
        />
      );
    },
  };

  const nextScan = {
    header: 'Next Discovery',
    align: 'left',
    render: ({ row }) =>
      row?.status?.code !== 'SUCCESS' ? (
        '-'
      ) : row?.is_paused ? (
        renderStatusTag(
          row?.is_paused ? 'PAUSED' : 'SCHEDULED',
          row?.is_paused && row?.paused_till ? 'orange.300' : '',
        )
      ) : (
        <StackedCell
          showUpperTooltip={false}
          showLowerTooltip={false}
          upper={
            row?.is_paused ? (
              (!!row?.paused_till &&
                dayjs(row?.paused_till).diff(dayjs(), 'year')) < 1 ? (
                <HStack>
                  <Box>Until</Box>
                  <Box>{renderTime({ value: row?.paused_till })}</Box>
                </HStack>
              ) : (
                'Indefinitely'
              )
            ) : (
              renderTime({ value: row?.next_discovery_time })
            )
          }
          lower={
            row.discovery_interval
              ? `Frequency: ${row.discovery_interval / 60} hours`
              : '-'
          }
        />
      ),
  };

  const frequency = {
    header: 'Frequency',
    render: ({ row }) => row.discovery_interval / 60 + ' hours',
  };

  const outpostDeployed = {
    header: renderTableHeaderWithLoader(
      'Ariksa Technologies',
      vulnerabilityScanner.isLoading || dataScanner.isLoading,
    ),
    render: ({ row }) => {
      const renderIcon = (type, isDeployed, tooltip) => (
        <CustomTooltip
          label={
            <Text w="full" textAlign="center">
              {tooltip}
            </Text>
          }
          w={32}
        >
          <Box
            color={
              isDeployed
                ? type === IconTypes.Vulnerability
                  ? 'critical'
                  : type === IconTypes.Remediate
                  ? 'green.300'
                  : 'primary'
                : 'gray.250'
            }
            //bg={isDeployed ? 'green.300' : 'gray.100'}
            boxSize={6}
            borderRadius={7}
            border="1px solid"
            borderColor="gray.200"
            p={0.5}
            cursor={
              isDeployed && type === IconTypes.Data ? 'pointer' : 'default'
            }
            onClick={() => {
              if (isDeployed && type === IconTypes.Data) {
                showDeployedOutposts.onOpen();
                setCurrentRow(row);
              }
            }}
          >
            <Center w="full" h="full">
              {getIcon(type)}
            </Center>
          </Box>
        </CustomTooltip>
      );
      const vulnerabilityScannerDeployed = !isEmpty(
        vulnerabilityScanner.data?.[row?.uuid],
      );
      const dataScannerDeployed = !isEmpty(dataScanner.data?.[row?.uuid]);
      return (
        row.cloud_type !== CloudProviders.Github &&
        row.cloud_type !== CloudProviders.Gitlab &&
        row.cloud_type !== CloudProviders.GitlabIac &&
        row.cloud_type !== CloudProviders.GithubIac &&
        row.cloud_type !== CloudProviders.Bitbucket &&
        row.cloud_type !== CloudProviders.BitbucketIac &&
        row.cloud_type !== CloudProviders.Snowflake &&
        row.cloud_type !== CloudProviders.Okta && (
          <HStack>
            {renderIcon(
              IconTypes.Vulnerability,
              vulnerabilityScannerDeployed,
              <Stack>
                <Box>
                  Outpost for Vulnerabilities
                  <Text
                    color={
                      vulnerabilityScannerDeployed ? 'green.300' : 'orange'
                    }
                  >
                    {vulnerabilityScannerDeployed ? ' ' : ' Not '}
                    Deployed
                  </Text>
                </Box>
                {vulnerabilityScannerDeployed && (
                  <Text>
                    <Text color="orange">Last scan: </Text>
                    <Text px={4}>
                      {renderTime({
                        value:
                          vulnerabilityScanner.data?.[row?.uuid]?.[0]
                            ?.last_seen,
                      })}
                    </Text>
                  </Text>
                )}
              </Stack>,
            )}
            {row.cloud_type !== CloudProviders.Gcp &&
              renderIcon(
                IconTypes.Data,
                dataScannerDeployed,
                <Box>
                  Outpost for Data
                  <Text color={dataScannerDeployed ? 'green.300' : 'orange'}>
                    {dataScannerDeployed ? '' : 'Not '}
                    Deployed
                  </Text>
                </Box>,
              )}
            {renderIcon(
              IconTypes.Jit,
              row?.jit_status,
              <Box>
                Just-In-Time (JIT) Access
                <Text color={row?.jit_status ? 'green.300' : 'orange'}>
                  {row?.jit_status ? '' : 'Not '}
                  Enabled
                </Text>
              </Box>,
            )}
            {row.cloud_type !== CloudProviders.Gcp &&
              renderIcon(
                IconTypes.Remediate,
                row?.remediation_status,
                <Box>
                  Remediation
                  <Text
                    color={
                      row?.remediation_status === 'SUCCESS'
                        ? 'green.300'
                        : 'orange'
                    }
                  >
                    {row?.remediation_status === 'INITIATED'
                      ? 'Initiated'
                      : row?.remediation_status === 'SUCCESS'
                      ? 'Enabled'
                      : 'Not Enabled'}
                  </Text>
                </Box>,
              )}
          </HStack>
        )
      );
    },
    align: 'left',
  };

  const columns: TableColumnProps[] = [
    name,
    type,
    added,
    onboarded,
    snapshotStatusColumn,
    //nextScan,
    frequency,
    outpostDeployed,
    {
      header: 'Actions',
      accessor: 'id',
      render: renderRow,
      align: 'center',
      styles: { width: 90 },
    },
  ];
  const memberColumns = [
    name,
    type,
    added,
    snapshotStatusColumn,
    nextScan,
    outpostDeployed,
  ];

  return { columns, memberColumns, showDeployedOutposts, currentRow };
};

export const snapshotColumns = [
  {
    header: 'Start Time',
    accessor: 'start_time',
    render: renderTime,
    align: 'left',
    styles: {
      minWidth: '200px',
    },
  },
  {
    header: 'Status',
    accessor: 'status',
    render: renderStatus,
    styles: {
      minWidth: '200px',
    },
  },
];

export const useCloudAccountId = () => {
  const { cloudAccounts } = useSelector(selectApp);
  const [accountMapping, setAccountMapping] = useState<
    Record<string, CloudAccountGetResponse>
  >({});

  useEffect(() => {
    const items: Record<string, CloudAccountGetResponse> = {};
    forEach(cloudAccounts.data, o => (items[o.uuid] = o));
    setAccountMapping(items);
  }, [cloudAccounts.data]);

  /*const getCloudAccount = useCallback(uuid => {
    return
  })*/

  const mapToCloudId = useCallback(
    (uuids: string[] = []) => {
      return (
        uuids
          .map(
            u =>
              cloudAccounts.data.find(a => a.uuid === u?.trim())
                ?.cloud_account_id,
          )
          .filter(identity) ?? []
      );
    },
    [cloudAccounts],
  );

  const toCloudAccountId = useCallback(
    uuid => {
      return accountMapping[uuid]?.cloud_account_id ?? '-';
    },
    [accountMapping],
  );

  const toCloudAccountName = useCallback(
    uuid => {
      const data = accountMapping[uuid];
      return data?.name ?? data?.cloud_account_id ?? '-';
    },
    [accountMapping],
  );

  const renderStackedCloudAccountWithCloud = uuid => {
    const account = accountMapping[uuid];
    const name = account?.name;
    const id = account?.cloud_account_id;
    const renderTooltipField = (label, value) => (
      <HStack spacing={1}>
        <Box color="orange">{label}:</Box>
        <Box>{value}</Box>
      </HStack>
    );
    return (
      <CustomTooltip
        label={
          <Stack>
            {name && renderTooltipField('Account Name', name)}
            {renderTooltipField('Account ID', id)}
            {renderTooltipField('Cloud', getProviderName(account?.cloud_type))}
          </Stack>
        }
      >
        <Stack spacing={0}>
          <Box>{id}</Box>
          <Box boxSize={4}>{getIcon(account?.cloud_type!)}</Box>
        </Stack>
      </CustomTooltip>
    );
  };

  const renderStackedAccountNameID = uuid => {
    const account = accountMapping[uuid];
    const name = account?.name;
    const id = account?.cloud_account_id;
    const renderTooltipField = (label, value) => (
      <HStack spacing={1}>
        <Box color="orange">{label}:</Box>
        <Box>{value}</Box>
      </HStack>
    );
    return (
      <CustomTooltip
        label={
          <Stack>
            {name && renderTooltipField('Account Name', name)}
            {renderTooltipField('Account ID', id)}
          </Stack>
        }
      >
        <StackedCell
          upper={name}
          lower={id}
          showUpperTooltip={false}
          showLowerTooltip={false}
        />
      </CustomTooltip>
    );
  };

  return {
    mapToCloudId,
    toCloudAccountId,
    toCloudAccountName,
    renderStackedCloudAccountWithCloud,
    accountMapping,
    renderStackedAccountNameID,
  };
};

export const CloudAccountName = ({ accountId }) => {
  const { toCloudAccountName, toCloudAccountId } = useCloudAccountId();
  return (
    <CustomTooltip
      label={`${toCloudAccountName(accountId)} (${toCloudAccountId(
        accountId,
      )})`}
    >
      <Box>{toCloudAccountName(accountId)}</Box>
    </CustomTooltip>
  );
};

export const isSaaSAccount = (account: CloudAccountGetResponse) => {
  return [
    CloudProviders.Github,
    CloudProviders.Gitlab,
    CloudProviders.GithubIac,
    CloudProviders.GitlabIac,
    CloudProviders.Bitbucket,
    CloudProviders.BitbucketIac,
    CloudProviders.Snowflake,
  ].includes(account.cloud_type as any);
};
