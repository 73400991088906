import {
  ClientParameterResponse,
  ClientResponse,
  ClientsApiGetClientByIdRequest,
  ClientsApiGetClientDetailsRequest,
  ClientsApiGetClientInfoRequest,
} from '@ariksa/notification/api';

import { NotificationService } from 'api/services';
import { useAriksaQuery } from 'utils/query/useAriksaQuery';

export function useNotificationClients() {
  const clients = useAriksaQuery<
    ClientsApiGetClientDetailsRequest,
    ClientResponse[]
  >({
    queryKey: [],
    fetcher: req => {
      return NotificationService.Clients.getClientDetails(req).then(
        d => d.data,
      );
    },
  });

  const client = useAriksaQuery<ClientsApiGetClientByIdRequest, ClientResponse>(
    {
      queryKey: [],
      fetcher: req => {
        return NotificationService.Clients.getClientById(req).then(d => d.data);
      },
    },
  );

  const clientInfo = useAriksaQuery<
    ClientsApiGetClientInfoRequest,
    ClientParameterResponse
  >({
    queryKey: [],
    fetcher: req => {
      return NotificationService.Clients.getClientInfo(req).then(d => d.data);
    },
  });

  return { clients, client, clientInfo };
}
