/**
 *
 * Widget
 *
 */
import React from 'react';

import {
  Box,
  CloseButton,
  Flex,
  Tooltip,
  useColorMode,
  Stack,
} from '@chakra-ui/react';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import { tooltipProps } from 'components/DataEntry';
import { InfoCircleIcon } from 'components/Icons';

import { IWidget, WidgetProps } from '../types';

import { widgetStyles } from './styles';

interface IWidgetProps {
  widget: IWidget;
  available: { [index: string]: WidgetProps };
}

export const Widget: React.FC<IWidgetProps> = ({ widget, available }) => {
  const { colorMode } = useColorMode();
  const themeStyles = widgetStyles[colorMode];
  //colorMode === 'dark' ? widgetStyles.dark : widgetStyles.light;
  const widgetBgColor = available[widget.i]?.bg
    ? available[widget.i]?.bg
    : 'cardBg';

  const headerHeight = '32px';
  const componentHeight = `calc(100% - ${headerHeight})`;

  return (
    available[widget.i] &&
    (available[widget.i]?.flex ? (
      <Flex
        {...widgetStyles.common}
        {...themeStyles}
        {...available[widget.i]?.styles?.root}
        bg={widgetBgColor}
        className={available[widget.i]?.className}
      >
        {available[widget.i].components?.content}
      </Flex>
    ) : (
      <Stack
        {...widgetStyles.common}
        {...themeStyles}
        {...available[widget.i]?.styles?.root}
        bg={widgetBgColor}
        className={available[widget.i]?.className}
      >
        <Box h={headerHeight}>
          <Flex justify="space-between">
            <Box w="full">
              <Box
                pr={1}
                pt={3}
                pl={2}
                pb={available[widget.i]?.showDivider ? 3 : 0}
                borderBottom={
                  available[widget.i]?.showDivider ? '1px solid' : 'none'
                }
                borderColor="gray.200"
              >
                <PageHeaderWithIcon
                  label={available[widget.i]?.name}
                  reversed
                  icon={available[widget.i]?.icon}
                  fontSize="md"
                  {...available[widget.i]?.styles?.title}
                />
              </Box>
              {/*<Heading
                fontSize="md"

                fontWeight={600}
                {...available[widget.i]?.styles?.title}
              >
                {available[widget.i]?.name}
              </Heading>*/}
              {available[widget.i]?.description && (
                <Tooltip
                  label={available[widget.i]?.description}
                  {...tooltipProps}
                  fontSize="sm"
                >
                  <Box
                    //color={colorMode === 'dark' ? 'transparent' : 'primary'}
                    ml={1}
                    mt={2}
                  >
                    <InfoCircleIcon />
                  </Box>
                </Tooltip>
              )}
            </Box>
            {available[widget.i]?.closeButton && (
              <CloseButton
                bg="transparent"
                color="primary"
                _hover={{ color: 'blue.700' }}
                _focus={{ outline: 'none' }}
                onClick={() => console.log(widget)}
              />
            )}
          </Flex>
        </Box>
        <Box
          h={componentHeight}
          p={2}
          pt={available[widget.i]?.showDivider ? 8 : 2}
          {...available[widget.i]?.styles?.componentWrapper}
        >
          {available[widget.i].components?.content}
        </Box>
      </Stack>
    ))
  );
};
