/**
 *
 * CreateOrg
 *
 */

import React, { memo, useCallback, useEffect } from 'react';

import Keycloak from 'keycloak-js';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useToasts } from 'components/Toast';
import { useKeycloakContext } from 'containers/App/keycloak/context';
import { updateSessionStoreTokens } from 'containers/App/keycloak/useKeyCloak';
import { useTrackApiCall } from 'hooks/useTrackApiCall';
import { useInjector } from 'utils/inject';

import { OrgDetails } from './OrgDetails';
import { organizationWizardSaga } from './saga';
import { selectOrganizationWizard } from './selectors';
import { actions, reducer, sliceKey } from './slice';

interface CreateOrgProps {}

export const CreateOrg = memo((props: CreateOrgProps) => {
  useInjector(sliceKey, reducer, organizationWizardSaga);

  const { keycloak } = useKeycloakContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const { successToast } = useToasts();

  const { createOrganization } = useSelector(selectOrganizationWizard);
  const createApiCall = useTrackApiCall(createOrganization);

  const handleReset = useCallback(() => {
    dispatch(actions.resetCreateOrganization());
    history.push('/organizations');
  }, [dispatch, history]);

  const handleCreateOrganization = () => {
    const { organization, admin_user } = createOrganization.data;
    // console.log(admin_user, organization);
    dispatch(
      actions.createOrganization({
        admin_user,
        ...organization,
      }),
    );
  };

  useEffect(() => {
    return () => {
      dispatch(actions.resetCreateOrganization());
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        if (createApiCall.success) {
          successToast({
            title: 'New organization created',
          });
          const refreshed = await keycloak?.updateToken(-1);
          if (refreshed) {
            updateSessionStoreTokens(keycloak);
            handleReset();
            return;
          }
        }
      } catch (e) {
        console.error('Failed to refresh token, redirecting to login page');
        handleReset();
      }
    })();
  }, [createApiCall.success, handleReset, successToast]);

  return (
    <OrgDetails
      action="Create"
      isLoading={createOrganization.loading}
      organization={createOrganization.data.organization}
      admin_user={createOrganization.data.admin_user}
      updateOrgDetailsFieldAction={actions.updateCreateOrganizationDetails}
      updateOrgUserDetailFieldAction={actions.updateCreateAdminDetails}
      handleUserFormSubmit={handleCreateOrganization}
      handleReset={handleReset}
    />
  );
});
