/**
 *
 * Profile Menu
 *
 */
import React, { useContext } from 'react';

import { Avatar, Box, MenuItem, useDisclosure, Center } from '@chakra-ui/react';
import { FaSignOutAlt, GrPowerReset, IoMdPerson } from 'react-icons/all';
import { Link } from 'react-router-dom';

import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { CustomerJourneyBulbIcon } from 'components/Icons';
import { MyTasks } from 'components/Navigation/NavBar/TopNavBar/RightComponent/MyTasks';

import { Menu } from '../../../../Overlay/Menu';
import { NavBarContext } from '../../context';
import { profileMenuStyles } from '../styles';

interface Props {}

export const ProfileMenu: React.FC<Props> = props => {
  const { onLogout, resetPassword, user } = useContext(NavBarContext);
  const tasks = useDisclosure();

  const menu = [
    {
      title: 'Explore',
      //url: '',
      icon: CustomerJourneyBulbIcon,
      onClick: () => {
        tasks.onOpen();
      },
    },
    { title: 'Your Profile', url: '/user-profile', icon: IoMdPerson },
    {
      title: 'Reset Password',
      onClick: resetPassword,
      icon: GrPowerReset,
      url: '',
    },
    { title: 'Sign Out', url: '/login', onClick: onLogout, icon: FaSignOutAlt },
  ];

  return (
    <Box {...profileMenuStyles.root}>
      <Menu
        buttonLabel={
          <CustomTooltip
            label={
              user.info?.first_name
                ? user.info?.first_name + ' ' + user.info?.last_name
                : user.info.username
            }
            placement={'bottom'}
          >
            <Box pos="relative">
              <Avatar
                size="sm"
                name={
                  user.info?.first_name
                    ? user.info?.first_name + ' ' + user.info?.last_name
                    : user.info.username
                }
                color="primary"
                fontWeight={600}
                bg="white"
                p={0.5}
                //src={user.info?.avatar}
                //src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              />
            </Box>
          </CustomTooltip>
        }
        itemsComponent={menu.map(each =>
          !each?.url ? (
            <MenuItem
              _hover={{ outline: 'none' }}
              _focus={{ outline: 'none', bg: 'primary', color: 'white' }}
              onClick={e => each.onClick && each.onClick(e)}
            >
              <Box mr={2} boxSize={5}>
                <Center>
                  <each.icon />
                </Center>
              </Box>
              {each.title}
            </MenuItem>
          ) : (
            <Link
              to={each?.url}
              key={`${each.title.split(' ').join('-')}-profile-menu`}
              onClick={e => each.onClick && each.onClick(e)}
            >
              <MenuItem
                _hover={{ outline: 'none' }}
                _focus={{ outline: 'none', bg: 'primary', color: 'white' }}
              >
                <Box mr={2}>
                  <each.icon />
                </Box>
                {each.title}
              </MenuItem>
            </Link>
          ),
        )}
        styles={{
          button: { ...profileMenuStyles.menuButton, mr: 0 },
        }}
      />
      {tasks.isOpen && (
        <MyTasks isOpen={tasks.isOpen} onClose={tasks.onClose} />
      )}
    </Box>
  );
};

export default ProfileMenu;
