/**
 *
 * Logo
 *
 */
import React from 'react';

import { Flex, FlexProps } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { AriksaLogoIcon } from 'components/Icons';

interface Props extends FlexProps {}

export const Logo: React.FC<Props> = props => {
  return (
    <Flex align="left" w="120px" {...props}>
      <Link to="/home">
        <AriksaLogoIcon />
      </Link>
    </Flex>
  );
};
