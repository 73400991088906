import React, { FC, useEffect, useMemo, useState } from 'react';

import { Box, Center, Flex, Stack, HStack } from '@chakra-ui/react';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import { FiChevronLeft, FiChevronRight } from 'react-icons/all';

import { Card } from 'components/DataDisplay';

import { collapseIconWrapperStyles, defaultStyles } from './styles';
import { LayoutProps, LayoutStyles } from './types';

export const Layout: FC<LayoutProps & LayoutStyles> = props => {
  const {
    leftComponent,
    middleComponent,
    rightComponent,
    outerWrapper,
    innerWrapper,
    rWidth = 'layoutLeftWidth',
    lWidth = 'layoutLeftWidth',
    isLeftCollapsable = false,
  } = props;

  const [leftWidth, setLeftWidth] = useState<string | number>(lWidth);
  const [rightWidth, setRightWidth] = useState<string | number>(
    'layoutLeftWidth',
  );
  const [isCollapsedLeft, setCollapsedLeft] = useState(false);

  useEffect(() => {
    if (lWidth) setLeftWidth(lWidth);
    if (rWidth) setRightWidth(rWidth);
  }, [lWidth, rWidth]);

  const baseOuterStyles = useMemo(() => {
    const props = cloneDeep(defaultStyles.outerWrapper);
    return merge(props, outerWrapper);
  }, [outerWrapper]);

  const baseInnerStyles = useMemo(() => {
    const props = cloneDeep(defaultStyles.innerWrapper);
    return merge(props, innerWrapper);
  }, [innerWrapper]);

  const toggleLeft = () => {
    if (isCollapsedLeft) {
      setLeftWidth(lWidth ?? 'layoutLeftWidth');
    } else {
      setLeftWidth(0);
    }
    setCollapsedLeft(!isCollapsedLeft);
  };

  return (
    <Box {...baseOuterStyles} className="layout">
      <Stack isInline {...baseInnerStyles}>
        {leftComponent &&
          (isLeftCollapsable ? (
            <HStack>
              {!isCollapsedLeft && (
                <Flex
                  h={'full'}
                  pos={'relative'}
                  overflow={'visible'}
                  w={leftWidth ?? 4}
                  left={parseInt(`${leftWidth}`) - parseInt(`${lWidth}`) + 'px'}
                >
                  <Box pos={'absolute'} h={'full'} zIndex={900}>
                    <Center
                      {...collapseIconWrapperStyles}
                      right={'-12px'}
                      onClick={toggleLeft}
                      as={FiChevronLeft}
                    >
                      {/*{isCollapsedLeft ? <FiChevronRight /> : <FiChevronLeft />}*/}
                    </Center>
                    <Box h={'full'} w={leftWidth}>
                      {leftComponent}
                    </Box>
                  </Box>
                </Flex>
              )}
              {isCollapsedLeft && (
                <Card
                  styles={{ card: { overflow: 'visible', p: 2 } }}
                  contentComponent={
                    <Box pos={'absolute'} h={'full'} zIndex={900}>
                      <Center
                        {...collapseIconWrapperStyles}
                        right={'-21px'}
                        onClick={toggleLeft}
                        as={FiChevronRight}
                      >
                        {/*{isCollapsedLeft ? <FiChevronRight /> : <FiChevronLeft />}*/}
                      </Center>
                    </Box>
                  }
                />
              )}
            </HStack>
          ) : (
            <Flex w={leftWidth} minW={leftWidth} overflow={'auto'} h={'full'}>
              {leftComponent}
            </Flex>
          ))}

        <Flex flex={1} maxW="full">
          <Box w="full" pos={'relative'}>
            {middleComponent ?? props.children ?? null}
          </Box>
        </Flex>

        {rightComponent && <Flex w={rightWidth}>{rightComponent}</Flex>}
      </Stack>
    </Box>
  );
};
