import { useCallback, useEffect, useState } from 'react';

import jwt_decode from 'jwt-decode';
import Keycloak from 'keycloak-js';
import { noop } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { usePrevious, useSearchParam } from 'react-use';

import { authOptions } from 'api/auth';
import { User, UserService, UserServiceGetResponse } from 'api/auth/api.pb';
import { BASE_URL, KEYCLOAK_SERVICE } from 'api/service_urls';
import { useApp } from 'containers/App/hooks/useApp';
import { gTag } from 'hooks/analytics/useTrackClick';
import { isDevEnv, isTestEnv } from 'utils/env';

import { selectApp } from '../selectors';
import { actions } from '../slice';

export const updateSessionStoreTokens = (
  keycloak?: Keycloak.KeycloakInstance,
) => {
  if (!keycloak) return;
  sessionStorage.setItem('authentication', keycloak.token as string);
  sessionStorage.setItem('refreshToken', keycloak.refreshToken as string);
};

const removeTokens = () => {
  sessionStorage.removeItem('realm');
  sessionStorage.removeItem('authentication');
  sessionStorage.removeItem('refreshToken');
};

/**
 * Keycloak authentication flow
 * 1. user sets the realm
 * 2. keycloak checks if already logged in
 *    2.1 on failure user is redirected to keycloak realm related login page
 *    2.2 on success go to step 5
 * 3. user logs in and gets redirected to initial application page
 * 4. step 2 is checked again with success
 * 5. now that the login is success, we keep refreshing the token at some interval
 * 6. user logout
 * 7. reset password
 * */

function useKeyCloakFn() {
  const dispatch = useDispatch();
  const { getUserAuth } = useApp();
  const history = useHistory();
  const params = useParams<{ idp: string }>();
  const app = useSelector(selectApp);
  const prevRealm = usePrevious(app.organization);
  const [keycloak, setKeycloak] = useState<Keycloak.KeycloakInstance | null>(
    null,
  );

  const loadUserRoles = useCallback(() => getUserAuth(), [getUserAuth]);

  // Authentication token must be updated in local storage before this
  const loadUserInfo = useCallback(
    (keycloak: Keycloak.KeycloakInstance) => {
      const token = keycloak.token as string;
      const tokenInfo: any = jwt_decode(token);

      const { sub } = tokenInfo;
      const data = {
        organization: app.organization,
        id: sub,
      };

      UserService.Get(data, authOptions())
        .then((res: UserServiceGetResponse) => {
          const userInfo = {
            ...res.user,
            account_ids: res.user?.account_ids,
            org_id: app.organization,
            org_uuid: tokenInfo?.organization_id,
          };
          dispatch(actions.loginSuccess({ userInfo, tokenInfo }));

          if (!isDevEnv()) {
            gTag('event', 'login', {
              user: res.user?.username,
              organization: app.organization,
            });
          }
        })
        .catch(e => {
          dispatch(actions.loginError(e));
        });
    },
    [app.organization, dispatch],
  );

  // try to authenticate
  // step 2
  const authenticate = useCallback(
    async (keycloak: Keycloak.KeycloakInstance) => {
      const redirectUri = new URL(window.location.href);
      redirectUri.searchParams.set('organization', app.organization);
      console.log(redirectUri);

      try {
        const authenticated = await keycloak.init({
          onLoad: 'login-required',
          redirectUri: redirectUri.toString(),
          checkLoginIframe: false,
        });

        // step 2.1
        if (!authenticated) {
          removeTokens();
          window.location.reload();
          return;
        }

        // step 2.2
        console.info('Authenticated');
        sessionStorage.setItem('realm', app.organization);

        updateSessionStoreTokens(keycloak);

        loadUserInfo(keycloak);
        loadUserRoles();
      } catch (e) {
        console.error('Authentication Failed');
      }
    },
    [app.organization, loadUserInfo, loadUserRoles],
  );

  // step 5
  const refreshToken = useCallback(
    (keycloak: Keycloak.KeycloakInstance, minValidity = 60) => {
      setInterval(async () => {
        console.log('trying to refresh token');
        try {
          const refreshed = await keycloak.updateToken(minValidity);
          if (refreshed) {
            console.log('Token is refreshed');
            updateSessionStoreTokens(keycloak);
            return;
          }

          const { tokenParsed: { exp = 0 } = {}, timeSkew = 0 } = keycloak;
          const validFor = exp + timeSkew - new Date().getTime() / 1000;

          const time = Math.round(validFor);
          console.warn(`Token not refreshed, valid for ${time} seconds`);
        } catch (e) {
          console.error('Failed to refresh token, redirecting to login page');
          history.push('/');
        }
      }, 10000);
    },
    [history],
  );

  const forceRefreshToken = useCallback(async () => {
    if (!keycloak) return;
    const refreshed = await keycloak.updateToken(100 * 86400);

    if (refreshed) {
      updateSessionStoreTokens(keycloak);
      loadUserInfo(keycloak);
      return;
    }
  }, [keycloak, loadUserInfo]);

  // try to extract existing realm from session or url
  useEffect(() => {
    const realm = sessionStorage.getItem('realm');
    const url = new URL(window.location.href);
    const queryRealm = url.searchParams.get('organization');
    dispatch(actions.setOrganization(realm || queryRealm || ''));
  }, [dispatch]);

  // try to authenticate
  // on success keep trying to refresh at some interval
  useEffect(() => {
    if (app.organization && prevRealm !== app.organization) {
      console.log(KEYCLOAK_SERVICE);
      const keycloak = new Keycloak({
        clientId: params.idp === 'okta' ? 'okta' : 'app',
        realm: app.organization,
        url: KEYCLOAK_SERVICE,
      });
      setKeycloak(keycloak);

      authenticate(keycloak).then(() => {
        refreshToken(keycloak);
      });
    }
  }, [app.organization, authenticate, params.idp, prevRealm, refreshToken]);

  // step 6
  const logOut = useCallback(() => {
    console.log('redirect url', BASE_URL);
    keycloak
      ?.logout({
        redirectUri: BASE_URL,
      })
      .then(res => {
        removeTokens();
        // authenticate(keycloak);
      });
  }, [keycloak]);

  // step 7

  const resetPassword = useCallback(() => {
    keycloak
      ?.login({
        action: 'UPDATE_PASSWORD',
      })
      .then(res => {
        // console.log(res);
      });
  }, [keycloak]);

  return { logOut, resetPassword, keycloak, forceRefreshToken };
}

function useKeyCloakNoop() {
  return {
    logOut: noop,
    resetPassword: noop,
    keycloak: noop,
    forceRefreshToken: noop,
  };
}

export let useKeyCloak;

useKeyCloak = isTestEnv() ? useKeyCloakNoop : useKeyCloakFn;
