/**
 *
 * Organizations
 *
 */

/**
 *
 * Organizations
 *
 */

import React, { memo } from 'react';

import Keycloak from 'keycloak-js';
import { Route, Switch } from 'react-router-dom';

import { useKeycloakContext } from 'containers/App/keycloak/context';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { OrganizationsRoot } from './Components/OrganizationsRoot';
import { CreateOrg } from './OrganizationWizard/CreateOrg';
import { EditOrg } from './OrganizationWizard/EditOrg';
import { organizationsSaga } from './saga';
import { reducer, sliceKey } from './slice';

interface Props {}

export const Organizations = memo((props: Props) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: organizationsSaga });

  return (
    <Switch>
      <Route
        path="/organizations/new-organization"
        render={props => <CreateOrg {...props} />}
      />
      <Route path="/organizations/edit/:org_id" component={EditOrg} />
      <Route path="/organizations" component={OrganizationsRoot} />
    </Switch>
  );
});
