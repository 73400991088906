/**
 *
 * Side Nav
 *
 */

import React, { memo, useCallback, useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { map } from 'lodash';
import { useRouteMatch } from 'react-router-dom';

import { getKeyMap } from 'components/Navigation/SideNav/utils';

import { Layout } from '../../Layout';

import { MiddleComponent } from './MiddleComponent';
import { NavigationComponent } from './NavigationComponent';
import { SideNavContextProps, SideNavContextProvider } from './SideNavContext';
import { SideNavKey, SideNavMenuItemProps, SideNavProps } from './types';

/**
 * SideNav provides a way to render nested data
 */
export const SideNav = memo((props: SideNavProps) => {
  //const isWide = useMedia(`(min-width: ${customTheme.sizes.minScreenWidth})`);
  const {
    current,
    menuItems = [],
    initialPath,
    styles,
    showContentHeader = true,
    navCompWidth,
    hasContent = true,
    height = 'full',
    isCollapsable = false,
    hasNavs = true,
    showPrimaryInBold = true,
  } = props;

  const [currentTab, setCurrentTab] = useState<SideNavMenuItemProps>({
    title: '',
    key: '',
  });
  const [currentPrimaryKeys, setCurrentPrimaryKeys] = useState<string[]>([]);
  const [keyMap, setKeyMap] = useState<Record<string, string[]>>({});
  const match = useRouteMatch(`${initialPath}/:tab`);
  const { tab: tabName = '' } = match?.params ?? ({} as any);

  useEffect(() => {
    getKeyMap(menuItems, keyMap, setKeyMap, []);
  }, [keyMap, menuItems]);

  const findTab = useCallback(
    (items: SideNavMenuItemProps[], key: SideNavKey) => {
      map(items, each => {
        if (each?.key === key) {
          setCurrentTab(each);
          return;
        } else if (each?.secondary) {
          findTab(each?.secondary, key);
        }
      });
    },
    [setCurrentTab],
  );

  const findInitialTab = useCallback(
    (item: SideNavMenuItemProps) => {
      if (item?.secondary?.length) {
        findInitialTab(item?.secondary[0]);
      } else {
        setCurrentTab(item);
        return;
      }
    },
    [setCurrentTab],
  );

  useEffect(() => {
    if (menuItems.length > 0) {
      if (current) {
        findTab(menuItems, current);
      } else if (initialPath && tabName) findTab(menuItems, tabName);
      else {
        findInitialTab(menuItems[0]);
      }
    } else
      setCurrentTab({
        title: '',
        key: '',
      });
  }, [
    menuItems,
    current,
    initialPath,
    tabName,
    findTab,
    findInitialTab,
    setCurrentTab,
  ]);

  useEffect(() => {
    if (currentTab) {
      setCurrentPrimaryKeys(keyMap[currentTab.key]);
    }
  }, [keyMap, currentTab]);

  const sideNavContextProps: SideNavContextProps = {
    showContentHeader,
    currentTab,
    setCurrentPrimaryKeys,
    currentPrimaryKeys,
    setCurrentTab,
    isWide: true,
    keyMap,
    ...props,
  };

  return (
    <SideNavContextProvider value={sideNavContextProps}>
      {hasContent ? (
        <Layout
          lWidth={navCompWidth ? navCompWidth : 64}
          leftComponent={<NavigationComponent />}
          middleComponent={<MiddleComponent currentTab={currentTab} />}
          outerWrapper={styles?.layout?.outerWrapper}
          spacing={styles?.layout?.spacing}
          isLeftCollapsable={isCollapsable}
        />
      ) : (
        <Box
          h={height}
          w={navCompWidth ? navCompWidth : 'full'}
          minW={navCompWidth ? navCompWidth : 64}
        >
          <NavigationComponent />
        </Box>
      )}
    </SideNavContextProvider>
  );
});
