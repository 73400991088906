import React, { FC, useCallback, useEffect, useState } from 'react';

import { Box, Center, HStack, Stack, useDisclosure } from '@chakra-ui/react';
import upperCase from 'lodash/upperCase';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dict } from 'types/utils';

import { renderAlertCategory, Tag } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { renderSeverityBubble } from 'components/DataDisplay/Utils/renderSeverity';
import { ActionButton } from 'components/DataEntry';
import { ShowIcon } from 'components/Icons';
import { PageInfo } from 'components/Navigation';
import { ResourceModal } from 'components/Overlay/Modal/ResourceModal';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { activeAssetsAlertUrl } from 'containers/Findings/Alerts/utils';
import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';
import { actions } from 'containers/Findings/FindingsOverview/slice';

interface Props {
  severities: Dict<any>;
  resourceType: string;
  resourceName?: string;
  resourceId?: string;
  uuid: string;
  alert_category?: string;
}

export const TableAlerts: FC<Props> = props => {
  //this is causing reloading of page in compliance
  //useInjector(dashboardSlice.sliceKey, dashboardSlice.reducer, dashboardSaga);

  const { withAccessBoundary } = useAccessBoundary();
  const dispatch = useDispatch();
  const history = useHistory();

  const { resourceFailedChecks } = useSelector(selectFindingsOverview);
  const {
    severities,
    uuid,
    resourceType,
    resourceName,
    resourceId,
    alert_category = '',
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    page_number: 1,
    page_size: 20,
  });
  const [severity, setSeverity] = useState('');
  const { CRITICAL: critical = 0, MEDIUM: medium = 0, LOW: low = 0 } =
    severities ?? {};

  const fetchResourceFailedChecks = useCallback(() => {
    dispatch(
      actions.loadResourceFailedChecks({
        q: withAccessBoundary({
          entityUuid: uuid,
          severity: upperCase(severity) as any,
          alertCategory: alert_category,
          pageNumber: pageInfo.page_number,
          pageSize: pageInfo.page_size,
        }),
      }),
    );
  }, [
    alert_category,
    dispatch,
    pageInfo.page_number,
    pageInfo.page_size,
    severity,
    uuid,
    withAccessBoundary,
  ]);

  useEffect(() => {
    if (isOpen) {
      fetchResourceFailedChecks();
    }
  }, [fetchResourceFailedChecks, isOpen, pageInfo, severity]);

  const tag = (severity, count) => (
    <Center
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        setSeverity(severity);
        onOpen();
      }}
      cursor="pointer"
    >
      <Tag
        label={count}
        styles={{
          tag: { bg: severity, color: 'white', mr: 0 },
          label: { color: 'inherit' },
        }}
      />
    </Center>
  );

  const columns = [
    {
      header: 'Description',
      accessor: 'message',
      styles: { textAlign: 'left', cell: { textAlign: 'left' } },
    },
    {
      header: 'Alert Category',
      accessor: 'alert_category',
      render: renderAlertCategory,
      styles: { textAlign: 'left', cell: { textAlign: 'left' } },
    },
    {
      header: 'Severity',
      accessor: 'severity',
      render: renderSeverityBubble,
      styles: { textAlign: 'left', cell: { textAlign: 'left' } },
    },
  ];

  const totalAlerts = () =>
    severities?.critical + severities?.medium + severities?.low;

  return (
    <>
      <>
        <Stack isInline spacing={1}>
          {critical !== 0 && tag('critical', critical)}
          {medium !== 0 && tag('medium', medium)}
          {low !== 0 && tag('low', low)}
        </Stack>
      </>

      {totalAlerts() === 0 ? '-' : null}

      <ResourceModal
        header={
          <Stack>
            <HStack>
              <Box>Alerts</Box>
              {resourceId && (
                <ActionButton
                  onClick={() => {
                    history.push(activeAssetsAlertUrl(uuid, resourceType));
                  }}
                  icon={<ShowIcon />}
                  label={'Redirect to Alerts Page'}
                  noHover
                />
              )}
            </HStack>
          </Stack>
        }
        body={
          <Table
            columns={columns}
            data={resourceFailedChecks?.data}
            isLoading={resourceFailedChecks.isLoading}
            pagination={{
              pageInfo,
              totalCount: resourceFailedChecks?.page.totalCount,
              onChange: setPageInfo,
            }}
          />
        }
        resourceInfo={{
          resourceType,
          resourceName,
          resourceId,
        }}
        styles={{ modal: { size: '4xl' } }}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};
