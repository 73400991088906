import React, { FC, ReactNode } from 'react';

import { Box, StackProps, Stack, BoxProps } from '@chakra-ui/react';

interface Props extends StackProps {
  label: string | ReactNode;
  value?: any | ReactNode;
  styles?: { label?: BoxProps; value?: BoxProps };
}

export const DetailsIdentifier: FC<Props> = props => {
  const { label, value, styles, ...rest } = props;
  return (
    <Stack spacing={2} isInline fontSize="15px" {...rest}>
      <Box fontWeight={600} color="gray.250" {...styles?.label}>
        {label}:
      </Box>
      <Box flex={1} color="black" {...styles?.value}>
        {value ? value : '-'}
      </Box>
    </Stack>
  );
};
