import { useMemo } from 'react';

import { CloudInsight } from '@ariksa/inventory-core/api';
import { Circle, HStack, Stack, Text } from '@chakra-ui/react';

import { Heading2, renderTime } from 'components/DataDisplay';

interface CloudInsightItemProps {
  item: CloudInsight;
  isLast?: boolean;
}

export const CloudInsightItem = (props: CloudInsightItemProps) => {
  const { item, isLast } = props;

  const circleColor = useMemo(() => {
    switch (item.category) {
      case 'Informational':
        return 'yellow.400';
      case 'Elevated Risk':
        return 'red.500';
      default:
        return 'red.500';
    }
  }, [item.category]);

  return (
    <HStack spacing={10} alignItems={'flex-start'}>
      <Circle size="20px" bg={circleColor} />
      <Stack>
        <HStack spacing={6}>
          <Heading2>{item.category ?? '-'}</Heading2>
          <Text>{renderTime({ value: item.event_time })}</Text>
        </HStack>
        <Text>{item.description ?? '-'}</Text>
      </Stack>
    </HStack>
  );
};
