import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { appSaga } from 'containers/App/saga';
import { actions, reducer, sliceKey } from 'containers/App/slice';
import { useInjector } from 'utils/inject';

export function useApp() {
  useInjector(sliceKey, reducer, appSaga);
  const dispatch = useDispatch();

  const getUserAuth = useCallback(() => {
    dispatch(actions.getUserRoles({ q: {} }));
  }, [dispatch]);

  return {
    getUserAuth,
  };
}
