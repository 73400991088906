import { useCallback, useEffect, useState } from 'react';

import { useQuery } from 'react-query';
import { QueryResult } from 'react-query/types/core/types';
import { UseQueryObjectConfig } from 'react-query/types/react/useQuery';

import { errorToast } from 'components/Toast';

interface AriksaQueryFetcher<TRequest> {
  fetcher?(req: TRequest): void;
}

interface ReactQueryFetcher<TRequest = any> {
  queryFn?(): void;
  __request?: TRequest;
}

export type AriksaQueryObject<TRequest, TResult, TError> = Omit<
  UseQueryObjectConfig<TResult, TError>,
  'queryFn'
> &
  (AriksaQueryFetcher<TRequest> & ReactQueryFetcher<TRequest>);

export type AriksaQueryResult<TRequest, TResult, TError> = QueryResult<
  TResult,
  TError
> & {
  sync(req: TRequest): any;
};

export function useAriksaQuery<TRequest = any, TResult = any, TError = any>(
  config: AriksaQueryObject<TRequest, TResult, TError>,
): AriksaQueryResult<TRequest, TResult, TError> {
  const [queryData, setQueryData] = useState<TRequest | any>(null);

  // const [queryKey, setQueryKey] = useState([]);

  // const onSuccess = res => {
  //   config.config?.onSuccess?.(res);
  //   setQueryData(null);
  // };

  // const onError = err => {
  //   console.error(err);
  //   console.dir(err);
  //
  //   // setQueryData(null);
  //   config.config?.onError?.(err);
  //   const relativeUrl = err.config.url.slice(window.location.origin.length);
  //   errorToast({
  //     title: err.message.toString(),
  //     description: relativeUrl,
  //   });
  // };

  const queryFn = useCallback(
    a => {
      return (queryData && config.fetcher?.(queryData)) ?? config.queryFn?.();
    },
    [config, queryData],
  );

  const { queryKey } = config;

  const ariksaQueryConfig: UseQueryObjectConfig<TResult, TError> = {
    queryKey: [queryKey, queryData],
    queryFn,
    config: {
      retry: false,
      ...config.config,

      // onSuccess,
      // onError,
    },
  };

  const queryResult = useQuery<TResult, TError>(ariksaQueryConfig);
  const sync = useCallback((req: TRequest) => setQueryData(req), []);
  const { error, isFetched } = queryResult as any;
  useEffect(() => {
    setQueryData(null);
    if (error) {
      const relativeUrl = error?.config?.url?.slice(window.location.origin.length);
      const msg =
        error.response?.data?.detail ?? error.response?.data?.toString();
      errorToast({
        title: msg ?? error.message.toString(),
        description: msg ? '' : relativeUrl,
        position: 'top-left',
      });
    }
  }, [error, isFetched]);

  Object.assign(queryResult, {
    sync,
  });

  return queryResult as any;
}
