import React, { useCallback, useEffect, useState } from 'react';

import { ChatRequest } from '@ariksa/ai-engine';
import { ChatResponse } from '@ariksa/ai-engine/api';
import { Box, HStack, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Optional } from 'types/utils';

import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { ActionButton } from 'components/DataEntry';
import { TrashIcon } from 'components/Icons';
import { usePageContentContext } from 'components/Layout';
import { selectActiveEnvironment } from 'containers/App/selectors';
import { BotResponse } from 'containers/ConversationalAI/Components/AiAssistant/BotResponse';
import { HumanRequest } from 'containers/ConversationalAI/Components/AiAssistant/HumanRequest';
import { AiSearchBox } from 'containers/ConversationalAI/Components/AiSearchBox';
import { selectConversationalAI } from 'containers/ConversationalAI/selectors';
import { actions } from 'containers/ConversationalAI/slice';
import { useSearchParams } from 'hooks/useSearchParams';

interface Props {}

export const AIAssistant = (props: Props) => {
  const { chatItems, chatSessionId, askAI } = useSelector(
    selectConversationalAI,
  );
  const { environmentId } = useSelector(selectActiveEnvironment);
  const params = useSearchParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { contentHeight } = usePageContentContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(true);

  const findSearchQuery = (intermediateSteps: any[]) => {
    for (const [tool] of intermediateSteps) {
      const { tool_input, tool: name } = tool;
      const { search_term } = tool_input;

      if (name === 'execute_search_term') {
        return search_term;
      }
    }

    return undefined;
  };

  const aiSearch = useCallback(
    (value: string) => {
      if (!value) return;
      setSearchTerm(value);
      const payload: ChatRequest = {
        query: value,
        environment_id: environmentId,
      };

      if (chatSessionId) {
        payload.session_id = chatSessionId!;
      }

      dispatch(
        actions.askAI({
          q: {
            chatRequest: payload,
          },
          onSuccess: (res: Optional<ChatResponse>) => {
            const {
              intermediate_steps = [],
              metadata = {},
              session_id = '',
              output,
            } = res ?? {};

            const searchQuery = findSearchQuery(intermediate_steps);
            console.log(res);

            const items = [
              {
                type: 'human',
                message: value,
              },
              {
                type: 'ai',
                message: output ?? '',
                query: metadata?.investigate?.query ?? searchQuery ?? '',
                wrapper: metadata?.investigate?.wrapper,
                metadata: {
                  ai_search: value,
                  generated_query:
                    metadata?.investigate?.query ?? searchQuery ?? '',
                },
              },
              ...chatItems,
            ];
            dispatch(actions.updateChatItems(items));
            dispatch(actions.updateChatSessionId(session_id));
            setSearchTerm(' '.repeat(Math.round(Math.random() * 100)));
            setShowSuggestions(true);
          },
          onError: () => {
            setSearchTerm(' '.repeat(Math.round(Math.random() * 100)));
            setShowSuggestions(true);
          },
        }),
      );
    },
    [chatSessionId, dispatch, environmentId, chatItems],
  );

  // handle search query from url
  // if query is present, redirect to ai-assistant page and perform search
  useEffect(() => {
    const { q } = params;
    if (q) {
      history.push(`/home/ai-assistant`);
      aiSearch(q);
      setShowSuggestions(false);
    }
  }, [aiSearch, history, params]);

  const handleSearch = (searchTerm: string) => {
    aiSearch(searchTerm);
  };

  return (
    <Stack
      pos={'absolute'}
      w={'full'}
      minH={'full'}
      pt={'120px'}
      bg={'rgba(93,62,219,0.2)'}
      top={0}
      left={0}
    >
      <Stack px={10} margin={'0 auto'} w={'1000px'} spacing={6}>
        <HStack w={'full'}>
          <Box flex={1}>
            <AiSearchBox
              handleSearch={(term, type) => {
                setShowSuggestions(false);
                handleSearch(term);
              }}
              isLoading={askAI.isLoading}
              value={searchTerm}
              onReset={() => {
                setSearchTerm('');
              }}
            />
          </Box>
          <CustomTooltip label="Clear Chat" aria-label="Clear Chat">
            <ActionButton
              icon={<TrashIcon />}
              onClick={() => {
                dispatch(actions.updateChatItems([]));
                dispatch(actions.updateChatSessionId(''));
              }}
            />
          </CustomTooltip>
        </HStack>
        <Stack spacing={2}>
          {chatItems.map((item, index) =>
            item.type === 'ai' ? (
              <>
                <BotResponse key={index} item={item} />
                <br />
              </>
            ) : (
              <HumanRequest key={index} item={item} />
            ),
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AIAssistant;
