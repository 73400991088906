import React, { FC } from 'react';

import { Box, Circle, IconButton, Tooltip } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { BellIcon } from 'components/Icons';
import { useNavBarContext } from 'components/Navigation/NavBar/context';

import { iconStyles } from '../styles';

interface INotification {}

export const Notification: FC<INotification> = props => {
  const { alerts } = useNavBarContext();
  const history = useHistory();
  // const [isOpen, setOpen] = useState(false);

  // const showAllAlerts = () => {
  //   history.push('/alerts');
  //   setOpen(false);
  // };

  // const renderNotifications = (alert, i) => {
  //   return (
  //     <Flex
  //       px={2}
  //       pt={2}
  //       borderTop={'1px solid'}
  //       borderColor={'#eee'}
  //       key={'alert' + i}
  //       justifyContent={'space-between'}
  //     >
  //       <Flex
  //         flex={1}
  //         white-space="nowrap"
  //         overflow="hidden"
  //         text-overflow="ellipsis"
  //       >
  //         {alert.message}
  //       </Flex>
  //       <Flex w={14}>{toPascalCase(alert.severity)}</Flex>
  //     </Flex>
  //   );
  // };

  const handleClick = e => {
    // if (alerts.length === 0) {
    history.push('/alerts/open');
    // e.preventDefault();
    // e.stopPropagation();
    // }
  };

  // {alerts.length !== 0 && (
  //   <Badge
  //     pos={'absolute'}
  //     bottom={'0px'}
  //     right={'0px'}
  //     borderRadius={5}
  //     style={{ background: 'red', color: '#fff' }}
  //   >
  //     {alerts.length}
  //   </Badge>
  // )}

  // console.log(alerts);

  return (
    <>
      {/* <Popover
        isOpen={isOpen}
        // onOpen={() => setOpen(true)}
        // onClose={() => setOpen(false)}
      > */}

      {/* <PopoverTrigger> */}

      <Tooltip label={alerts.length} hasArrow placement="top" borderRadius={6}>
        <Box pos={'relative'}>
          <IconButton
            aria-label="Alerts and Notifications"
            icon={<BellIcon />}
            {...iconStyles}
            p={1}
            onClick={handleClick}
          />
          {alerts.length !== 0 && (
            <Circle size={2} bg="red" pos="absolute" top={0} right={1} />
          )}
        </Box>
      </Tooltip>

      {/* </PopoverTrigger> */}

      {/* <PopoverContent zIndex={4} mr={2}>
          <PopoverArrow />
          <PopoverBody p={0}>
            <Stack w={'full'}>
              <Stack px={2}>
                {alerts.slice(0, 6).map(renderNotifications)}
              </Stack>
              <Flex {...notificationButtonStyles} onClick={showAllAlerts}>
                Show Alerts
              </Flex>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover> */}
    </>
  );
};
