import {
  CloudAccountApiGetCloudAccountsRequest,
  PageCloudAccountGetResponse,
} from '@ariksa/cloud-account/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { QueryAction } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';

import {
  UserGroupServiceCreateRequest,
  UserGroupServiceCreateResponse,
  UserGroupServiceGetRequest,
  UserGroupServiceGetResponse,
  UserGroupServiceListRequest,
  UserGroupServiceListResponse,
  UserGroupServiceUpdateRequest,
  UserGroupServiceUpdateResponse,
} from 'api/auth/api.pb';
import { onApiCall, onApiCallError, onApiCallSuccess } from 'api/call_status';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the SetupUsers container
export const initialState: ContainerState = {
  /*allRoles: [
    'SaaS Admin',
    'Cloud Admin',
    'SaaS Admin -Read Only',
    'Cloud Admin -Read Only',
    'Org Admin',
    'AdminInfrastructure Admin',
    'Cloud Services Admin',
    'Monitoring Admin',
    'Compute Admin',
    'Data Admin',
    'Database Admin',
    'Network Admin',
    'DevOps Admin',
    'Compliance Admin',
  ],*/
  loadGroupById: {
    loading: false,
    error: null,
  },
  groupCreate: QueryState.init({}),
  accounts: PagedQueryState.init([]),
  groupUpdate: {
    loading: false,
    error: null,
    payload: {},
  },
  groups: {
    loading: false,
    error: null,
    data: [],
  },
  delete: {
    loading: false,
    error: null,
  },
};

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    changeValue(state, action: PayloadAction<any>) {},
    getCloudAccounts(
      state,
      action: QueryAction<
        PageCloudAccountGetResponse,
        CloudAccountApiGetCloudAccountsRequest
      >,
    ) {
      state.accounts = PagedQueryState.next(state.accounts, action, {
        mapData: r => r.items,
        mapTotalCount: r => r.total,
      });
    },
    /*Create Group*/
    createGroup(
      state,
      action: QueryAction<
        UserGroupServiceCreateResponse,
        UserGroupServiceCreateRequest
      >,
    ) {
      state.groupCreate = QueryState.next(state.groupCreate, action);
    },
    getGroupById(state, action: PayloadAction<UserGroupServiceGetRequest>) {
      onApiCall(state.loadGroupById);
      state.groupUpdate = initialState.groupUpdate;
    },
    getGroupByIdSuccess(
      state,
      action: PayloadAction<UserGroupServiceGetResponse>,
    ) {
      state.groupUpdate.payload = action.payload.user_group!;
      onApiCallSuccess(state.loadGroupById);
    },
    getGroupByIdError(state, action: PayloadAction<any>) {
      onApiCallError(state.loadGroupById, action.payload);
    },

    updateGroup(state, action: PayloadAction<UserGroupServiceUpdateRequest>) {
      onApiCall(state.groupUpdate);
      state.groupUpdate.payload.admins = action.payload.user_group?.admins;
    },
    updateGroupSuccess(
      state,
      action: PayloadAction<UserGroupServiceUpdateResponse>,
    ) {
      onApiCallSuccess(state.groupUpdate);
    },
    updateGroupError(state, action: PayloadAction<any>) {
      onApiCallError(state.groupUpdate, action.payload);
    },

    /*Fetch Group List*/
    loadGroups(state, action: PayloadAction<UserGroupServiceListRequest>) {
      state.groups.data = [];
      onApiCall(state.groups);
    },
    loadGroupsSuccess(
      state,
      action: PayloadAction<UserGroupServiceListResponse>,
    ) {
      state.groups.data = action.payload.user_groups ?? [];
      onApiCallSuccess(state.groups);
    },
    loadGroupsError(state, action: PayloadAction<any>) {
      state.groups.data = [];
      onApiCallError(state.groups, action.payload);
    },
    deleteGroup(state, action: PayloadAction<any>) {
      onApiCall(state.delete);
    },
    deleteGroupSuccess(state, action: PayloadAction<any>) {
      onApiCallSuccess(state.delete);
    },
    deleteGroupError(state, action: PayloadAction<any>) {
      onApiCallError(state.delete, action.payload);
    },
  },
});

export const { actions, reducer, name: sliceKey } = groupsSlice;
