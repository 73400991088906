import React from 'react';

import { Icon } from '@chakra-ui/react';

export const AccessKeyIcon = props => (
  <Icon
    viewBox="0 0 728 728"
    w="full"
    //p={0.5}
    h="full"
    color="inherit"
    {...props}
  >
    <defs>
      <clipPath id="clip0">
        <path
          d="M1002.04 358.693 641.307 722.036 277.964 361.307 638.693-2.03631Z"
          fill-rule="nonzero"
          clip-rule="nonzero"
        />
      </clipPath>
      <clipPath id="clip1">
        <path
          d="M1002.04 358.693 641.307 722.036 277.964 361.307 638.693-2.03629Z"
          fill-rule="evenodd"
          clip-rule="evenodd"
        />
      </clipPath>
      <clipPath id="clip2">
        <path
          d="M1002.04 358.693 641.307 722.036 277.964 361.307 638.693-2.03629Z"
          fill-rule="evenodd"
          clip-rule="evenodd"
        />
      </clipPath>
    </defs>
    <g transform="translate(-276 4)">
      <g>
        <g clipPath="url(#clip0)">
          <g clipPath="url(#clip1)">
            <g clipPath="url(#clip2)">
              <path
                d="M641.076 716.734C648.147 716.85 653.925 711.172 654.041 704.1L653.331 507.385C653.33 507.102 653.471 506.96 653.47 506.677 653.469 506.394 653.327 506.253 653.326 505.97L652.491 274.889C677.513 271.971 701.941 261.417 721.104 242.115 765.914 196.981 765.65 124.008 720.445 79.1278 675.24 34.248 602.338 34.5819 557.529 79.7159 512.791 124.92 512.983 197.823 558.117 242.632 577.42 261.796 601.853 272.244 626.965 274.911L627.756 493.971 563.692 494.202C560.298 494.215 556.98 495.57 554.584 497.983 552.189 500.396 550.928 503.653 550.869 506.977 550.894 514.048 556.643 519.755 563.714 519.729L627.777 519.498 628.054 596.289 563.991 596.52C560.597 596.533 557.278 597.888 554.883 600.301 552.487 602.714 551.085 605.971 551.168 609.295 551.193 616.366 556.941 622.072 564.012 622.047L628.076 621.816 628.372 703.84C628.61 711.122 634.146 716.617 641.076 716.734ZM575.696 97.7524C610.571 62.6245 667.281 62.4198 702.409 97.295 737.537 132.17 737.741 188.88 702.866 224.008 667.92 259.065 611.211 259.27 576.154 224.465 540.955 189.661 540.821 132.88 575.696 97.7524Z"
                fill="currentColor"
                fill-rule="nonzero"
                fillOpacity="1"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
