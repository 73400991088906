/**
 *
 * Landing Page
 *
 */

import React, { useMemo } from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { colorOpacity, customTheme } from 'theme';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import {
  AwsIcon,
  BlueprintViewIcon,
  DatabaseIcon,
  DownloadIcon,
  GoogleCloudIcon,
  PolicyIcon,
  SummaryIcon,
  VulnerabilityIcon,
  WorkflowIcon,
  ClockIcon,
  ListOfInstancesIcon,
  SuppressIcon,
} from 'components/Icons';
import { usePageContentContext } from 'components/Layout';
import { Tabs } from 'components/Navigation';
import { selectApp } from 'containers/App/selectors';
import { AiSearchBox } from 'containers/ConversationalAI/Components/AiSearchBox';
import { Explore } from 'containers/ConversationalAI/Components/Explore';
import { Insights } from 'containers/ConversationalAI/Components/Insights';
import { MyTasks } from 'containers/ConversationalAI/Components/MyTasks';
import { selectConversationalAI } from 'containers/ConversationalAI/selectors';

interface Props {}

export const ConversationalAILandingPage: React.FC<Props> = props => {
  const { user, cloudAccounts } = useSelector(selectApp);
  const { contentHeight } = usePageContentContext();
  const history = useHistory();
  const { cloudInsights } = useSelector(selectConversationalAI);

  const getGreetingMessage = () => {
    const currentHour = new Date().getHours();

    return currentHour >= 4 && currentHour < 12 // after 4:00AM and before 12:00PM
      ? 'Good morning'
      : currentHour >= 12 && currentHour <= 17 // after 12:00PM and before 6:00pm
      ? 'Good afternoon'
      : currentHour > 17 || currentHour < 4 // after 5:59pm or before 4:00AM (to accommodate night owls)
      ? 'Good evening' // if for some reason the calculation didn't work
      : 'Welcome';
  };

  const list = useMemo(
    () => [
      ...(!!cloudAccounts.data?.length
        ? [
            { label: 'Dashboard', link: '/dashboard', icon: <SummaryIcon /> },
            {
              label: 'Findings',
              link: '/findings',
              icon: <BlueprintViewIcon />,
            },
            {
              label: 'Data',
              link: '/visibility/data',
              icon: <DatabaseIcon p={1} />,
            },
            {
              label: 'Vulnerabilities',
              link: '/visibility/vulnerability',
              icon: <VulnerabilityIcon />,
            },
            {
              label: 'JIT',
              link: '/setup/jit',
              icon: <ClockIcon />,
            },
            {
              label: 'Workflows',
              link: '/policy-hub/workflow',
              icon: <WorkflowIcon p={0.5} />,
            },
            {
              label: 'Policies',
              link: '/policy-hub/policy',
              icon: <PolicyIcon p={0.5} />,
            },
            {
              label: 'Reports',
              link: '/reports/all',
              icon: <DownloadIcon p={1} />,
            },
          ]
        : []),
      {
        label: 'AWS',
        link: '/setup/accounts/add/aws',
        icon: <AwsIcon pt={1} />,
      },
      {
        label: 'GCP',
        link: '/setup/accounts/add/gcp',
        icon: <GoogleCloudIcon />,
      },
      /*{
      label: 'Azure',
      link: '/setup/accounts',
      icon: <AzureActiveDirectoryIcon />,
    },*/
    ],
    [cloudAccounts.data],
  );

  const renderNoData = (label, icon) => {
    return (
      <Center w="full" h="full">
        <Stack align="center">
          <Center boxSize={14} color="gray.200">
            <Center>{icon}</Center>
          </Center>
          <Center color="gray.200" fontSize="lg" h="full">
            {label}
          </Center>
        </Stack>
      </Center>
    );
  };

  const tabs = useMemo(
    () => [
      ...(!!cloudAccounts.data?.length
        ? [
            {
              title: 'Insights',
              key: 'Insights',
              component: cloudInsights.isLoading ? (
                <Box h="full">
                  <CustomSpinner size="lg" />
                </Box>
              ) : isEmpty(cloudInsights.data) ? (
                renderNoData('No run-time insights', <SuppressIcon />)
              ) : (
                <Insights />
              ),
            },
          ]
        : []),
      {
        title: 'Explore',
        key: 'Explore',
        component: <Explore />,
      },
      /*{
        title: 'Event log',
        key: 'Event log',
        component: <Explore />,
      },*/
    ],
    [cloudAccounts.data, cloudInsights.data],
  );

  const handleSearch = (searchTerm: string) => {
    history.push(`/home/ai-assistant?q=${searchTerm}`);
  };

  return (
    <Box
      bg={colorOpacity(customTheme.colors.primary, 0.2)}
      h={contentHeight + 'px'}
      px={40}
      pb={6}
      pt={10}
    >
      <Stack w="full" spacing={10} h="full">
        <Box color="primary" fontSize={28} px={10}>
          {getGreetingMessage()}, {user.info.first_name}
        </Box>
        {cloudAccounts.loading ? (
          <Center flex={1}>
            <CustomSpinner size="xl" color="white" />
          </Center>
        ) : (
          <>
            {!cloudAccounts.data?.length && (
              <Box fontSize={24} px={10} pt={4}>
                Get started
              </Box>
            )}
            {!!cloudAccounts.data?.length && (
              <Box px={10}>
                <AiSearchBox
                  handleSearch={handleSearch}
                  isLoading={false}
                  showSuggestions={true}
                />
              </Box>
            )}
            <HStack spacing={6} px={10} align="flex-start">
              {list.map(o => (
                <>
                  <Box minW={24}>
                    <Stack w="full">
                      <Center>
                        <Center
                          boxSize={16}
                          p={4}
                          color="primary"
                          borderRadius="full"
                          bg="white"
                          onClick={() => history.push(o.link)}
                          cursor="pointer"
                        >
                          <Center>{o.icon}</Center>
                        </Center>
                      </Center>
                      <Center
                        w="full"
                        fontSize={16}
                        color="gray.300"
                        onClick={() => history.push(o.link)}
                        cursor="pointer"
                      >
                        {o.label}
                      </Center>
                    </Stack>
                  </Box>
                </>
              ))}
            </HStack>
            <HStack px={10} flex={1} pt={4} spacing={6} overflow="hidden">
              <Box
                borderRadius={6}
                bg="white"
                w="60%"
                h="full"
                p={4}
                overflow="scroll"
              >
                <Tabs
                  items={tabs}
                  styles={{
                    tabList: { border: 'none' },
                    tab: { fontSize: 'md' },
                    tabPanel: { pt: 0 },
                  }}
                />
              </Box>
              <Box borderRadius={6} bg="white" w="40%" h="full" p={4} pl={8}>
                <Tabs
                  items={[
                    {
                      title: 'My Tasks',
                      key: 'tasks',
                      component: renderNoData(
                        'No Tasks',
                        <ListOfInstancesIcon />,
                      ),
                      /*component: !!cloudAccounts.data?.length ? (
                        <MyTasks />
                      ) : (
                        <Center color="gray.200" fontSize="lg" h="full" pb={16}>
                          No Tasks
                        </Center>
                      ),*/
                    },
                  ]}
                  styles={{
                    tabList: { border: 'none' },
                    tab: { fontSize: 'lg', px: 0 },
                    tabPanel: { pt: 0 },
                  }}
                />
              </Box>
            </HStack>
          </>
        )}
      </Stack>
    </Box>
  );
};
