/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { useState } from 'react';

import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Box, ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet-async';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { GRAPHQL_SERVICE } from 'api/service_urls';
import { Authentication } from 'containers/Authentication';

import { customTheme } from '../theme';

import 'react-sortable-tree/style.css';
import Main from './containers/App';

import 'focus-visible/dist/focus-visible';
import 'react-day-picker/dist/style.css';

import { EventEmitter } from 'events';

import { BusContext } from 'hooks/useBus';
import { TaskToastComponent } from 'containers/CustomerJourney/TaskToastComponent';

// const TRACKING_ID = 'G-7VGG00X5XR'; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID, { debug: true });
// ReactGA.set({ domain: 'none' });

const queryCache = new QueryCache();

const authLink = setContext((_, { headers }) => {
  // get the authentication token from session storage
  const token = sessionStorage.getItem('authentication');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});
export const graphqlLink = createHttpLink({
  uri: GRAPHQL_SERVICE,
});
const graphqlClient = new ApolloClient({
  link: authLink.concat(graphqlLink),
  cache: new InMemoryCache(),
});

function App() {
  const [bus] = useState(new EventEmitter());

  return (
    <BusContext.Provider value={bus}>
      <BrowserRouter>
        <Helmet titleTemplate="%s - ARIKSA" defaultTitle="ARIKSA">
          <meta name="description" content="ARIKSA" />
          <style>@import url(font.css);</style>
        </Helmet>
        <ChakraProvider resetCSS theme={customTheme}>
          <ReactQueryCacheProvider queryCache={queryCache}>
            <ApolloProvider client={graphqlClient}>
              <Switch>
                <Route path={'/auth'} component={Authentication} />
                <Route component={Main} />
              </Switch>
              {/*<RoleAccessReport />*/}
              {/*<UserAccessReports />*/}
              <TaskToastComponent />
              <Box className={'portal-container'} />
            </ApolloProvider>
          </ReactQueryCacheProvider>
        </ChakraProvider>
      </BrowserRouter>
    </BusContext.Provider>
  );
}

export default Sentry.withProfiler(App, { name: 'WebApp' });
