import React, { FC, useCallback } from 'react';

import { entries } from 'lodash';
import { useHistory } from 'react-router-dom';

import { OrganizationLoginInput } from 'containers/HomePage/OrganizationLoginInput';
import { useSearchParams } from 'hooks/useSearchParams';

interface ILoginOrganization {}

export const LoginOrganization: FC<ILoginOrganization> = props => {
  const history = useHistory();
  const params = useSearchParams();

  const handleSubmit = useCallback(
    data => {
      const queryString = entries(params)
        .map(([k, v]) => {
          return `${k}=${v}`;
        }, '')
        .join('&');

      const redirectTo = `/auth/login?${queryString}&organization=${data.organization}`;
      history.push(redirectTo);
    },
    [history, params],
  );

  return <OrganizationLoginInput handleSubmit={handleSubmit} />;
};
